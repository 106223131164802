.new-password-form {
	width: 20rem;
	margin-top: 1rem;
}

.new-password-form input {
	display: block;
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	outline: none;
	border: 1px solid silver;
	margin-top: 0.3rem;
	font-size: 0.9rem;
}

@media screen and (min-width: 0px) and (max-width: 380px) {
}
