/* ============================= */
#three-section-jobDetails-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
	margin-bottom: 5rem;
}

#three-section-jobDetails-container {
	display: flex;
	justify-content: space-between;
}

#three-section-jobDetails-container .left-container {
	width: 12vw;
}
#three-section-jobDetails-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-jobDetails-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

/* ============================================ */

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-jobDetails-container {
		padding-inline: 10vw;
	}

	#three-section-jobDetails-container .left-container,
	#three-section-jobDetails-container .right-container {
		width: 15vw;
	}

	#three-section-jobDetails-container .mid-container {
		width: 46vw;
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-jobDetails-container {
		padding-inline: 5vw;
	}

	#three-section-jobDetails-container .left-container,
	#three-section-jobDetails-container .right-container {
		width: 17.5vw;
	}

	#three-section-jobDetails-container .mid-container {
		width: 50vw;
	}
}

@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-jobDetails-container {
		padding-inline: 1vw;
	}

	#three-section-jobDetails-container .left-container,
	#three-section-jobDetails-container .right-container {
		width: 20vw;
	}

	#three-section-jobDetails-container .mid-container {
		width: 54vw;
	}
}

@media (min-width: 850px) and (max-width: 949px) {
	#three-section-jobDetails-container {
		padding-inline: 10vw;
	}

	#three-section-jobDetails-container .left-container {
		width: 25vw;
	}

	#three-section-jobDetails-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-jobDetails-container .right-container {
		display: none;
	}
}

@media (min-width: 641px) and (max-width: 849px) {
	#three-section-jobDetails-container {
		padding-inline: 2vw;
	}

	#three-section-jobDetails-container .left-container {
		width: 24vw;
	}

	#three-section-jobDetails-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-jobDetails-container .right-container {
		display: none;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	#three-section-jobDetails-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
	}

	#three-section-jobDetails-container {
		display: block;
	}

	#three-section-jobDetails-container .right-container,
	#three-section-jobDetails-container .left-container {
		display: none;
	}

	#three-section-jobDetails-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	/* .detailed-job-title-section {
		padding: 0.5rem;
		padding-bottom: 1rem;
	} */

	.detailedJob-job-title {
		font-size: 0.95rem;
		font-weight: 600;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-jobDetails-container {
		padding-inline: 0vw;
	}

	#three-section-jobDetails-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-jobDetails-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	.detailedJob-company img {
		height: 3.5rem;
		width: 3.5rem;
		margin-right: 0.5rem;
	}

	.detailed-company-name {
		font-size: 1.2rem;
		font-weight: 550;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	.detailed-job-title-section {
		padding: 0.5rem 0.3rem;
	}

	.detailedJob-company img {
		height: 3rem;
		width: 3rem;
		margin-right: 0.5rem;
	}

	.detailed-company-name {
		font-size: 1rem;
		font-weight: 550;
	}

	.detailedJob-job-title {
		font-size: 0.9rem;
		font-weight: 600;
	}

	.jobPost-date {
		font-size: 0.69rem !important;
	}

	.detailedJob-applicant {
		font-size: 0.6rem;
	}
	.job-noLonger {
		width: 130px;
		font-size: 0.8rem;
	}
}
