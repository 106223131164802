#two-section-createCompany-container {
	display: flex;
	margin-top: 5rem;
	padding-left: 15vw;
	padding-right: 15vw;
	margin-bottom: 5rem;
}

.user-leftContainer {
	width: 52vw;
	margin-right: 3vw;
	border-radius: 10px;
	background-color: white;
}

.user-rightContainer {
	width: 15vw;
}

/* CREATE COMPANY PAGE */

.create-company-section {
	width: 70vw;
	margin-inline: 15vw;
	margin-top: 5rem;
	margin-bottom: 5rem;
}

.created-company {
	position: relative;
}

.create-company-section .heading-section {
	padding-top: 2rem;
}

.create-company-section .heading {
	text-align: center;
}

.create-company-section .main-line {
	font-size: 1.1rem;
	color: var(--subHeading-text-color);
}

.create-company-section ul li {
	margin-bottom: 1.5rem;
	border-radius: 5px;
	padding: 1rem;
	position: relative;
}

.create-company-section li p {
	font-weight: 550;
	font-size: 1.1rem;
	color: var(--subHeading-text-color);
}

.create-company-section ul li img,
.add-company-section {
	height: 5rem;
	/* width: 8rem; */
	border-radius: 5px;
}

.form-add-icon {
	height: 3.5rem;
	width: 4rem;
	color: var(--primary-color);
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.create-company-section span {
	margin-left: 1rem;
}

.create-company-section .company-sample-img {
	/* height: 50%;
	width: 30%; */
	display: flex;
	align-self: center;
}
/* ==================== COMPANY FORM ============================= */
.company-form {
	padding: 3rem 0;
	position: relative;
}

.company-form input,
.company-form select,
.company-form textarea,
.company-form label {
	width: 100%;
	display: block;
	background-color: #eef3f8;
	padding: 0.3rem;
	outline: none;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	font-size: 0.9rem;
}

.company-form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
	width: 100%;
	background-color: var(--primary-light-color);
}

.company-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.company-form .autoComplete,
.company-form input:nth-child(1) {
	background-color: #eef3f8;
	outline: none;
	margin-bottom: 1rem;
	border-radius: 5px;
	border: none;
	margin: 0rem !important;
}

.company-form label {
	background-color: white;
	border: none;
}

#company-logo-upload {
	border: 2px dashed #e0e0e0;
	width: 90%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-bottom: 1rem;
	padding: 1rem 0;
}

/* =========================CREATED COMPANY======================= */

.created-company .company-image-section {
	position: relative;
}

.created-company .overview {
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.created-company .company-img {
	position: absolute;
	top: 50%;
	left: 5%;
}

.company-overview-section {
	display: flex;
	justify-content: space-between;
}

.company-select {
	width: 10rem;
	height: 2rem;
	display: flex;
	margin-right: 2rem;
	align-self: flex-end;
	border: none;
	outline: none;
	background-color: var(--primary-light-color);
}

.company-options {
	border-radius: 10px;
	padding: 10rem;
	font-size: 0.9rem;
	border: none;
}

.company-overview-section p {
	margin-left: 2rem;
	font-size: 0.9rem;
	font-weight: 550;
	width: 50%;
	margin-bottom: 1rem;
}

/* padding: var(--company-page-padding); */

/* COMPANY REDIRECT NAVBAR */

.company-redirect {
	display: flex;
	border-top: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;
	margin-top: 1rem;
}

.company-redirect li {
	padding: 0.8rem;
	margin-left: 1.5rem;
	cursor: pointer;
}

.company-redirect li {
	color: var(--subHeading-text-color);
	font-weight: bolder;
}

.company-details-section h4 {
	margin-bottom: 0.3rem;
}

.company-details-section p {
	font-size: 0.9rem;
	width: 95%;
	color: var(--subHeading-text-color);
	font-weight: 500;
}

/* COMPANY ABOUT SECTION */

.company-details-section {
	position: relative;
}

.company-about-section {
	background-color: var(--primary-light-color);
	padding: 0.5rem 1rem 0.5rem 2rem;
	margin-bottom: 1rem;
}

.update-company-btn {
	position: absolute;
	right: 2%;
	top: 2%;
}

.company-flex-section {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 2rem;
}

.company-flex-section > li {
	width: 50%;
}

.company-flex-section .second-list {
	padding-left: 5rem;
}

/* Website */
.company-website {
	color: var(--primary-color);
}

.company-website:hover {
	text-decoration: underline;
}

/* social media */
.company-socials {
	position: relative;
	margin-bottom: 1rem;
}

.company-socialmedia {
	display: flex;
	margin-top: 1rem;
}

.company-socialmedia li img {
	margin-right: 1rem;
	height: 1.5rem;
	width: 1.5rem;
}

.companySocial-edit-btn,
.companySocial-add-btn {
	position: absolute;
	top: 2%;
	right: 2%;
}

/* address */

.company-address-section {
	position: relative;
	margin: 0.5rem 1rem 0.5rem 2rem;
}

.company-investment-section {
	margin: 0.5rem 1rem 0.5rem 2rem;
}

.address-add-btn {
	position: absolute;
	right: 0.5%;
	top: 3%;
}

.company-address {
	position: relative;
}

.address-edit-btn {
	position: absolute;
	top: 1%;
	right: 2%;
}

.headquarter-checkbox {
	width: auto !important;
	display: inline !important;
	margin-left: 1rem;
}
/* Industry */

.industry-section {
	position: relative;
}

.speciality-btn {
	position: absolute;
	right: 1%;
	top: 1%;
}

/* Map */

.map-section {
	padding: 0.5rem 1rem 0.5rem 2rem;
}

.map-section img {
	width: 100%;
}

/* Vlog */

.vlog-section {
	padding: 0.5rem 1rem 0.5rem 2rem;
	position: relative;
}

.vlog-edit-btn {
	position: absolute;
	top: 2%;
	right: 2%;
}

.vlog-flex-section {
	display: flex;
	justify-content: space-around;
	margin-top: 0.5rem;
}

.vlog-flex-section img {
	height: 3rem;
	border-radius: 5px;
}

.vlog-flex-section li {
	max-width: 85%;
	/* text-align: justify; */
}
/* Recent Posts */

.recent-posts {
	padding: var(--company-page-padding);
}

.recent-posts-card {
	margin-top: 1rem;
}

#slick-div {
	margin: 0rem 1rem;
}

#slick-div img {
	position: relative !important;
	left: 50% !important;
	transform: translate(-50%) !important;
	height: 8rem;
	width: 12rem;
}

#slick-div #card-content {
	text-align: center;
}

/* Videos */

.company-videos-section {
	padding: var(--company-page-padding);
}

.company-videos {
	display: flex;
}

.company-videos img {
	object-fit: fill;
}

.video-link-section {
	margin-left: 5rem;
}

/* ================JOBS POST PAGE================= */

.view-applicant-mobile {
	display: none;
}

.posted-jobs-info-section {
	/* padding: var(--company-page-padding); */
	padding: 0.5rem 1rem 0.5rem 2rem;
	position: relative;
}

.posted-jobs-info-section span {
	color: black;
}

.posted-jobs-flex {
	/* display: flex; */
	/* justify-content: space-between; */
	background-color: var(--primary-light-color);
	padding: 0.3rem;
	/* padding: 0.5rem 0; */
	/* padding-right: 10%; */
}

.posted-jobs-flex img {
	height: 3rem;
	width: 3rem;
	border-radius: 5px;
}
/* .posted-jobs-flex span {
	display: block;
} */

.posted-jobs-flex span a {
	color: black;
}

.posted-jobs-flex span a:hover {
	text-decoration: underline;
}

.posted-job-description {
	word-wrap: break-word;
}

/* .posted-jobs-flex span:first-child {
	font-weight: bold;
} */

.posted-jobs-flex .view-applicant {
	width: 10%;
}

.posted-jobs-flex li:last-child span {
	display: inline;
}

.update-job-btn {
	position: absolute;
	top: 15%;
	right: 5%;
}

.view-applicant a {
	color: black;
}

/* .view-applicant:hover {
	text-decoration: underline;
} */

.job-details-open {
	cursor: pointer;
}

.job-details-open:hover {
	text-decoration: underline;
}

/* TEAM MEMBERS PAGE */

.company-member-section {
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
	position: relative;
	margin-bottom: 1rem;
	margin-right: 1.5%;
	margin-left: 1.5%;
	padding-bottom: 0.5rem;
	width: 22%;
	min-height: 12rem;
}

.team-member-section {
	position: relative;
	margin-top: 1rem;
	/* display: flex;
	flex-wrap: wrap; */
	padding-top: 2rem;
}

.teams-member-section {
	display: flex;
	flex-wrap: wrap;
}

.memberAdd-btn {
	text-align: -webkit-right;
	margin-right:2rem;
}

.member-bg-img img {
	width: 100%;
	height: 5rem;
	object-fit: cover;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.member-profile-img img {
	height: 4rem;
	height: 4rem;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	border-radius: 50%;
	top: 2.5rem;
}

.member-name {
	text-align: center;
	margin-top: 2rem;
	font-weight: bold;
}

.member-designation {
	font-size: 0.8rem;
	font-weight: 550;
	text-align: center;
	padding: 0 0.3rem;
	color: var(--subHeading-text-color);
}

.member-edit-btn {
	position: absolute;
	top: 5%;
	right: 5%;
	color: lightgray;
}

.company-edit-btn {
	top: 3.8rem;
	right: 0.5rem;
}
/* --------------------------------------------------- */

.company-dropdown {
	position: absolute !important;
	/* top: 1.5rem; */
	right: 0;
	margin-right: 1rem;
}

/* MODAL CSS testing, not final */

/* ================= MEDIA QUERY SECTION =========================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#two-section-createCompany-container {
		padding-inline: 10vw;
	}

	.user-leftContainer {
		width: 65vw;
	}

	.user-rightContainer {
		width: 15vw;
	}

	.create-company-section {
		width: 80vw;
		margin-inline: 10vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#two-section-createCompany-container {
		padding-inline: 5vw;
	}

	.user-leftContainer {
		width: 75vw;
	}

	.user-rightContainer {
		width: 15vw;
	}

	.create-company-section {
		width: 90vw;
		margin-inline: 5vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#two-section-createCompany-container {
		padding-inline: 1vw;
	}

	.user-leftContainer {
		width: 79vw;
	}
	.user-rightContainer {
		width: 19vw;
	}

	.create-company-section ul {
		width: 60%;
	}

	.create-company-section {
		width: 98vw;
		margin-inline: 1vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#two-section-createCompany-container {
		padding-inline: 10vw;
		display: block;
	}
	.user-leftContainer {
		width: 80vw;
	}

	.user-rightContainer {
		display: none;
	}

	.create-company-section {
		width: 80vw;
		margin-inline: 10vw;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#two-section-createCompany-container {
		padding-inline: 2vw;
		display: block;
	}
	.user-leftContainer {
		width: 96vw;
	}

	.user-rightContainer {
		height: 20rem;
		display: none;
	}

	.create-company-section {
		width: 96vw;
		margin-inline: 2vw;
	}

	.company-hero-section {
		display: block !important;
	}

	/* .company-hero-section ul {
		display: grid;
		grid-template-columns: 2fr 2fr;
		grid-column-gap: 2rem;
	} */
	.create-company-section .company-hero-section ul {
		display: flex;
		justify-content: space-around;
		width: 100%;
	}

	.create-company-section .company-hero-section ul li {
		/* position: relative;
		left: 50%;
		transform: translate(-10%); */
		/* width: 120%; */
		width: 48%;
	}

	.company-sample-img {
		display: flex;
		justify-content: center;
		height: 20rem;
		width: 100% !important;
	}

	.company-hero-section p,
	.company-hero-section h2 {
		font-size: 0.9rem !important;
	}

	.create-company-section ul li {
		padding: 0.5rem;
	}

	.create-company-section ul li img {
		height: 4rem;
		width: 6rem;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	#two-section-createCompany-container {
		padding-inline: 0;
		display: block;
		margin-top: 3rem;
	}

	.user-rightContainer {
		display: none;
	}

	#two-section-createCompany-container .user-leftContainer {
		margin-bottom: 6rem;
	}

	.company-flex-section {
		display: block;
		width: 100%;
	}

	.create-company-section {
		width: 98vw;
		margin-inline: 1vw;
	}

	.company-overview-section {
		position: relative;
	}

	.company-sample-img,
	.create-company-section .company-hero-section ul {
		width: 100% !important;
	}

	.company-select {
		position: absolute;
		right: 0;
		bottom: 45%;
		margin-right: 0.5rem;
	}

	.company-flex-section .first-list,
	.company-flex-section .second-list {
		width: 100%;
	}

	.company-flex-section .second-list {
		padding-left: 0rem;
	}

	.teams-member-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.company-member-section {
		width: 94%;
		margin-left: 2%;
		margin-right: 2%;
	}

	.company-mobile-flex {
		display: flex;
		align-items: center;
	}

	.company-mobile-flex h4 {
		display: flex;
		align-items: center;
		width: 50%;
	}

	.company-mobile-flex p {
		width: 50%;
	}

	.posted-jobs-flex .first-li {
		width: 40%;
		margin-bottom: 0.5rem;
	}

	.view-applicant {
		display: none !important;
	}

	.view-applicant-mobile {
		display: flex;
		font-size: 0.7rem;
	}

	.company-about-section,
	.company-flex-section,
	.map-section,
	.vlog-section,
	.posted-jobs-info-section {
		padding-left: 1rem;
	}

	.recent-post-heading {
		margin-left: -1rem;
	}

	.company-address-section {
		margin-left: 1rem;
	}

	.posted-jobs-flex {
		display: block;
	}

	.posted-jobs-flex .first-li {
		width: 100%;
		padding-left: 0.3rem;
		padding-right: 0.3rem;
	}

	.posted-jobs-flex .second-li {
		padding-right: 0.3rem;
	}

	#slick-div img {
		position: relative !important;
		left: 50% !important;
		transform: translate(-55%) !important;
		height: 10rem;
		width: 16rem;
	}

	.create-company-section {
		margin-top: 2rem;
	}

	.company-hero-section {
		display: block !important;
	}

	.create-company-section li p,
	.create-company-section li h2,
	.create-company-section .main-line {
		font-size: 0.9rem;
	}

	.company-success {
		box-shadow: none !important;
		border: none !important;
	}
	.company-success h1 {
		font-size: 1rem;
	}

	.company-success {
		height: calc(100vh - 12rem);
	}
	.user-company-leftContainer {
		background: #f4f5f7 !important;
	}

	/* .view-company-img {
		height: 5rem;
		width: 6rem !important;
		object-fit: fill !important;
	} */

	.company-select {
		position: absolute;
		right: 0px;
		bottom: 45%;
		margin-right: 0.5rem;
	}

	.mobile-addjob-btn {
		right: 0.5rem !important;
	}

	.posted-job-description,
	.posted-job-description .read-more-btn {
		font-size: 0.8rem;
		color: var(--subHeading-text-color);
	}

	.posted-job-title-mobile {
		font-size: 0.9rem !important;
		font-weight: 650 !important;
	}

	.company-dropdown {
		width: 15rem !important;
		top: 6rem !important;
		z-index: 10;
		margin-right: 1rem;
	}
}

@media (min-width: 0px) and (max-width: 450px) {
	.company-about-section,
	.company-flex-section,
	.map-section,
	.vlog-section {
		padding: 0.5rem 1rem;
	}

	.company-address-section,
	.company-investment-section {
		margin: 0.5rem 1rem;
	}

	.company-redirect li {
		font-size: 0.8rem;
		margin-left: 0.5rem;
	}

	.company-about-section .overview,
	.vlog-section p,
	.recent-posts p {
		font-size: 0.8rem;
	}

	.created-company .company-img {
		height: 4rem;
		width: 4rem;
		top: 75%;
	}

	.company_name {
		margin-top: 3rem !important;
		margin-left: 1rem !important;
	}

	.company-title-section {
		margin-left: 1rem !important;
	}

	.posted-jobs-info-section {
		padding: 0.5rem 0;
	}

	.posted-jobs-flex img {
		height: 3rem;
		width: 3rem;
	}

	.company-socialmedia {
		display: flex;
		margin-top: 0rem;
	}

	.companySocial-edit-btn {
		top: 20%;
	}

	.company-sample-img {
		height: 15rem;
	}
	.company-dropdown {
		width: 12rem !important;
	}
}

@media (min-width: 0px) and (max-width: 380px) {
	.company-form {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
	}

	.company-socialmedia li img {
		margin-right: 0.7rem;
		height: 1.2rem;
		width: 1.2rem;
	}

	.created-company .company-redirect {
		width: 100%;
		justify-content: space-around;
		margin-top: 0.5rem;
	}

	.created-company .company-redirect li {
		padding: 0.5rem 0;
		margin: 0;
	}

	.company-select {
		font-weight: lighter;
		margin-left: 1rem;
		width: 8rem;
		top: 0.5rem;
	}

	.company-redirect li {
		font-size: 0.8rem;
	}

	.posted-jobs-flex img {
		height: 2.5rem;
		width: 2.5rem;
	}

	.company-sample-img {
		height: 12rem;
	}

	.company-sample-img img {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		margin-top: -1rem;
	}

	.posted-job-location-mobile,
	.posted-jobs-flex .second-li {
		margin-left: 3rem !important;
	}

	.posted-job-location-mobile li {
		font-size: 0.8rem !important;
		font-weight: 500;
	}
	.company-dropdown {
		width: 10rem !important;
	}
}

@media (max-width: 300px) {
	.company-socialmedia li img {
		margin-right: 0.7rem;
		height: 1rem;
		width: 1rem;
	}

	.company-sample-img {
		height: 10rem;
	}

	.company-address span {
		font-size: 0.85rem;
	}

	.company-about-section,
	.company-flex-section,
	.map-section,
	.vlog-section {
		padding: 0.5rem 0.5rem !important;
	}

	.company-address-section {
		margin: 0.5rem 0.5rem !important;
	}

	.company-dropdown {
		width: 9rem !important;
	}

	.company-dropdown input {
		padding-left: 2.2rem !important;
	}
}

.google-map > div {
	position: unset !important;
	/* position: unset !important; */
}

.google-map {
	height: 20rem !important;
}
