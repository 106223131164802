/* ============================= */
#three-section-connection-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-connection-container {
	display: flex;
	justify-content: space-between;
}

#three-section-connection-container .left-container {
	width: 12vw;
}
#three-section-connection-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-connection-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}
/* ============================= */

/* ============CONNECTED PEOPLE==================== */

.connected-people-container,
.send-connection-container,
.received-connection-container {
	background-color: white;
	min-height: 70vh;
	max-height: auto;
}

.connected-people-container > p,
.send-connection-container > p,
.received-connection-container > p {
	margin-bottom: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.connected-flex-section {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 1rem;
	background-color: white;
	/* align-items: center; */
}

.connected-flex-section .connected-nested-flex {
	display: flex;
	/* align-items: center; */
}

.connected-nested-flex .connected-userName {
	color: var(--heading-text-color);
	line-height: 1.2rem;
}

.connected-nested-flex .connected-title {
	font-size: 0.9rem;
}

.connected-flex-section .connected-button-section {
	display: flex;
	align-items: flex-start;
}

.connected-button-section button:last-child {
	display: flex;
	align-items: center;
}
.send-nested,
.send-connection,
.received-connection,
.connected-connection {
	min-width: 80%;
	width: 85%;
}

.connected-nested-flex {
	min-width: 65% !important;
}

.received-nested-flex {
	min-width: 75%;
}
.send-connection,
.received-connection {
	width: 80%;
}

/* .common-connection-mobile {
	width: 90vw;
} */

/* ====================================================== */

/* =============Send Connection ==========================*/

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-connection-container {
		padding-inline: 10vw;
	}

	#three-section-connection-container .left-container,
	#three-section-connection-container .right-container {
		width: 15vw;
	}

	#three-section-connection-container .mid-container {
		width: 46vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-connection-container {
		padding-inline: 5vw;
	}

	#three-section-connection-container .left-container,
	#three-section-connection-container .right-container {
		width: 17.5vw;
	}

	#three-section-connection-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-connection-container {
		padding-inline: 1vw;
	}

	#three-section-connection-container .left-container,
	#three-section-connection-container .right-container {
		width: 20vw;
	}

	#three-section-connection-container .mid-container {
		width: 54vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-connection-container {
		padding-inline: 10vw;
	}

	#three-section-connection-container .left-container {
		width: 25vw;
	}

	#three-section-connection-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-connection-container .right-container {
		display: none;
	}

	.received-nested-flex {
		min-width: 70%;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-connection-container {
		padding-inline: 2vw;
	}

	#three-section-connection-container .left-container {
		width: 24vw;
	}

	#three-section-connection-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-connection-container .right-container {
		display: none;
	}

	.received-nested-flex {
		min-width: 65% !important;
	}
}

.connected-button-section-mobile {
	display: none;
}
@media screen and (orientation: landscape) and (max-width: 950px) and (max-height: 500px) {
	.connected-button-section {
		display: none !important;
	}
	.connected-button-section-mobile {
		display: flex;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-connection-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
		margin-bottom: 5rem;
	}

	#three-section-connection-container {
		display: block;
	}

	#three-section-connection-container .right-container,
	#three-section-connection-container .left-container {
		display: none;
	}

	#three-section-connection-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}

	#connection-info-card {
		border-radius: 0;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.logo {
		height: 3.5rem;
		width: 3.5rem;
		margin-right: 0.7rem;
	}

	.received-connection-container {
		height: auto;
	}

	.send-connection-container {
		height: auto;
	}

	.connected-mobile-sreen {
		width: 70%;
	}

	/* .connected-flex-section .connected-nested-flex .common-connection-profile {
		width: 15%;
	} */

	.common-connection-mobile {
		width: 85%;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-connection-container {
		padding-inline: 0vw;
	}

	#three-section-connection-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-connection-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	#connection-details .logo {
		height: 3rem;
		width: 3rem;
		margin-right: 0.3rem;
	}

	#connection-details .starup-socialMedia img {
		height: 22px;
		width: 22px;
	}

	.connected-userName p {
		font-size: 0.9rem;
	}

	.connected-title p {
		font-size: 0.8rem;
	}
	/* 
	.connected-flex-section .connected-nested-flex .common-connection-profile {
		width: 18%;
	}

	.common-connection-mobile {
		width: 82%;
	} */
}

@media (min-width: 0px) and (max-width: 400px) {
	#connection-details .starup-socialMedia ul li {
		margin-right: 0.3rem;
	}

	#connection-details .starup-socialMedia img {
		height: 20px;
		width: 20px;
	}

	.first-row-table tr th,
	.second-row-table tr th {
		width: 33.3%;
		color: var(--subHeading-text-color);
		font-size: 12px;
	}

	.pending-btn {
		display: none;
	}

	.connected-button-section button:last-child {
		margin-left: 0;
	}
}

@media (min-width: 0px) and (max-width: 400px) {
	.connected-userName p {
		font-size: 0.8em;
		font-weight: bold;
	}

	.connected-flex-section {
		padding-right: 5px;
	}

	.mobile-username-limit {
		width: 150px;
		text-overflow: ellipsis;
	}
	/* .connected-flex-section .connected-nested-flex .common-connection-profile {
		width: 20%;
	}

	.common-connection-mobile {
		width: 80%;
	} */

	.received-nested-flex {
		min-width: 70vw !important;
	}

	.received-connection,
	.connected-connection {
		min-width: 70%;
		width: 70%;
	}

	.send-connection {
		min-width: 75%;
		width: 75%;
	}
}

@media (min-width: 0px) and (max-width: 300px) {
	/* .connected-flex-section .connected-nested-flex .common-connection-profile {
		width: 25%;
	}

	.common-connection-mobile {
		width: 75%;
	} */
}
