.registration-container {
  width: 70%;
  margin: auto;
  display: flex;

  margin-top: 5rem;

  border-radius: 5px;
  padding: 2rem 1rem;
  background-color: white;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.side-banner {
  flex-basis: 60%;
}
.company-logo {
  width: 50%;
  margin-bottom: 4rem;
}

.banner-img {
  border-radius: 50%;
  height: 20rem;
  width: auto;
}

.form-wrapper {
  flex-basis: 80%;
}

.registration-form {
  width: auto;
  flex-basis: 40%;
  /* margin-top: 5rem; */
}

.registration-form h1 {
  text-align: center;
  color: #0a66c2;
  font-weight: bold;
  margin-bottom: 1rem;
}

.registration-form input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid silver;
  margin-top: 0.3rem;
}

.registration-form input:focus {
  border: 1px solid #0a66c2;
}

.registration-form label {
  display: block;
  font-size: 0.8rem;
  font-weight: bold;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
}
.input-container {
  position: relative;
}
.input-container input {
  width: 100%;
  padding-right:50px ;
}
.img-container{
  margin-left: 3rem;
}
.input-icon {
  position: absolute;
  width: 1.5rem;
  bottom: 0.5rem;
  right: 1rem;
}

.toggle-btn-container {
  display: flex;
  justify-content: space-between;
}

.toggle-btn {
  margin-top: 2rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* color: white; */

}

.toggle-btn.next{
  border-radius: 8px ;
  background-color: #2575ee !important;
  color:white;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) ;
}
.toggle-btn:hover {
  color: #96bee6;
  font-weight: 700;
}

.toggle-btn:disabled {
  color: rgb(199, 193, 193) !important;
}
.toggle-btn.next:disabled {
  background-color:white !important;
  color: rgb(199, 193, 193) !important;
  box-shadow: none;
}

.sign_in-btn,
.resume-reg-btn {
  margin-top: 1rem;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  outline: none;
  color: white;
  border-radius: 5px;
}

.sign_in-btn:hover {
  transition: 0.3s all ease;
  background: #0077ee;
  color: #f3f3f3;
}

.login-page-redirect {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.login-page-redirect span {
  font-weight: bold;
  color: var(--primary-color);
  cursor: pointer;
}

.login-page-redirect span:hover {
  text-decoration: underline;
  transition: all 0.2s ease;
}

.PhoneInput {
  border: 1px solid silver !important;
  border-radius: 5px;
}

#phone {
  width: 70%;
  border: none;
  margin-top: 0;
}

.PhoneInputCountry {
  background: white;
  margin-right: 0;
  margin-left: 0.2rem !important;
}

.signup-options {
  /* display: flex; */
  /* align-items: end; */
}
.icon-btn {
  /* height: 3rem; */
  width: 100%;
  /* margin: 0 0.5rem; */
}

@media (min-width: 640px) and (max-width: 1080px) {
  .registration-container {
    justify-content: center;
    flex-basis: 100%;
    flex-direction: column;
  }
  .company-logo {
    margin-bottom: 0;
    width: 70%;
    margin: 0 auto;
  }
  .banner-img {
    display: none !important;
  }
}

@media (min-width: 0px) and (max-width: 640px) {
  .registration-container {
    flex-direction: column;
  
  }
  .company-logo {
    width: 100%;
    margin-bottom: 0;
   margin: 0 auto;
  }
  .registration-form {
    justify-content: center;
    flex-basis: 100%;
    margin-bottom: 8rem;
  }
  .banner-img {
    display: none !important;
  }
  .verification-logo {
    height: auto !important;
  }
}

@media (min-width: 0px) and (max-width: 450px) {
  .registration-container{
    width:100%;
    margin-top:3rem;
    box-shadow:none;
    background-color: #f4f5f7;;
  }
}
