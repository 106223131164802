/* ============================= */
#three-section-notification-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-notification-container {
	display: flex;
	justify-content: space-between;
}

#three-section-notification-container .left-container {
	width: 12vw;
}
#three-section-notification-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-notification-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}

/* ============================= */
.notification-flex-section > li {
	border-bottom: 1px solid silver;
	padding-bottom: 0.2rem;
	padding: 0.9rem 1rem 0.7rem 1rem;
	background:white;
}

.notification-flex-section .flex-upper-section {
	display: flex;
	justify-content: space-between;
}

/* .notification-flex-section .flex-lower-section {
	display: flex;
	width: 50%;
	justify-content: space-around;
	margin-top: 0.3rem;
} */

/* .notification-flex-section .flex-lower-section button {
	outline: none;
	border: 1px solid silver;
	font-weight: 550;
	color: var(--subHeading-text-color);
	transition: all 0.3s ease;
}

.notification-flex-section .flex-lower-section button:hover {
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
	transition: all 0.3s ease;
} */

.notification-flex-section .notification-data {
	font-size: 0.9rem;
	font-weight: 550;
	color: var(--heading-text-color);
}

.notification-flex-section .notification-time {
	font-size: 0.7rem;
	font-weight: 550;
	color: var(--subHeading-text-color);
}
.notification-container {
	width: auto  !important;
	right: auto !important;
	left: 0 !important;
	height: calc(100vh - 5rem);
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-notification-container {
		padding-inline: 10vw;
	}

	#three-section-notification-container .left-container,
	#three-section-notification-container .right-container {
		width: 15vw;
	}

	#three-section-notification-container .mid-container {
		width: 46vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-notification-container {
		padding-inline: 5vw;
	}

	#three-section-notification-container .left-container,
	#three-section-notification-container .right-container {
		width: 17.5vw;
	}

	#three-section-notification-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-notification-container {
		padding-inline: 1vw;
	}

	#three-section-notification-container .left-container,
	#three-section-notification-container .right-container {
		width: 20vw;
	}

	#three-section-notification-container .mid-container {
		width: 54vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-notification-container {
		padding-inline: 10vw;
	}

	#three-section-notification-container .left-container {
		width: 25vw;
	}

	#three-section-notification-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-notification-container .right-container {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-notification-container {
		padding-inline: 2vw;
	}

	#three-section-notification-container .left-container {
		width: 24vw;
	}

	#three-section-notification-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-notification-container .right-container {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-notification-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
		margin-bottom: 5rem;
	}

	#three-section-notification-container {
		display: block;
	}

	#three-section-notification-container .right-container,
	#three-section-notification-container .left-container {
		display: none;
	}

	#three-section-notification-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}

	#notification-info-card {
		border-radius: 0;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.logo {
		height: 3.5rem;
		width: 3.5rem;
		margin-right: 0.7rem;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-notification-container {
		padding-inline: 0vw;
	}

	#three-section-notification-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-notification-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	#notification-details .logo {
		height: 3rem;
		width: 3rem;
		margin-right: 0.3rem;
	}

	#notification-details .starup-socialMedia img {
		height: 22px;
		width: 22px;
	}

	/* .notification-data {
		width: 75%;
	} */

	.notification-time {
		width: 25%;
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.notification-flex-section > li {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.notification-flex-section li img {
		margin-right: 0.5rem;
	}

	.notification-flex-section .notification-redirect {
		width: 75%;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	#notification-details .starup-socialMedia ul li {
		margin-right: 0.3rem;
	}

	#notification-details .starup-socialMedia img {
		height: 20px;
		width: 20px;
	}

	.first-row-table tr th,
	.second-row-table tr th {
		width: 33.3%;
		color: var(--subHeading-text-color);
		font-size: 12px;
	}

	.notification-flex-section > li {
		padding: 0.9rem 0.3rem 0.7rem 0.3rem;
	}

	.flex-upper-section {
		width: 100%;
	}

	.notification-flex-section .notification-redirect {
		width: 70%;
	}

	.notification-time {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.notification-flex-section .notification-data {
		font-size: 0.7rem;
	}

	.notification-accept-btn {
		margin-left: 3rem !important;
	}
}
