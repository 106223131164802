:root {
	--heading-text-color: #354044;
	--subHeading-text-color: #455a64;
	--details-text-color: #354044;
	--profile-card-color: rgba(217, 226, 240, 0.411);
	--profile-card-shadow: 2px 2px 4px #acb5c2;
	--heading-text-size: 20px;
	--subHeading-text-size: 15px;
	--details-text-size: 12px;
	--details-text-bigSize: 14px;
	--laptop-heading-text-size: 20px;
	--primary-color: #458af0;
	--primary-lightBlue-color: #ecf3fd;
	--primary-light-color: #eef3f8;
	--company-page-padding: 0.5rem 2rem 0.5rem 2rem;
}
