.modalBackground {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 45;
}

.modalContainer {
	width: 25rem;
	height: auto;
	border-radius: 5px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.modal-fullContainer {
	outline: none;
	border: none;
	background-color: white;
}

.modalContainer .title {
	display: inline;
	text-align: center;
}

.modalContainer .confirm-modal-body {
	flex: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.9rem;
	text-align: center;
	z-index: 2;
	margin: 0.5rem 0;
}

.modalContainer .footer {
	flex: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalBackground {
	overflow-y: hidden;
}

.delete-box-btn {
	color: #b96667;
}

/* =========== for image slider==============*/
.modal-background {
	background-color: rgba(0, 0, 0, 0.7);
}

.modal-background .slider-img {
	max-height: 35vh;
}


@media (min-width: 0px) and (max-width: 500px) {
	.modalContainer {
		width: 90vw;
		margin-inline: 5vw;
	}
}
