.posted-job-left-container::-webkit-scrollbar {
	display: none;
}

.posted-details-tab::-webkit-scrollbar {
	display: none;
}

.posted-job-right-container::-webkit-scrollbar{
	display: none;
}

/* calendar modal css */
.job-update-form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
	/* width: 90%; */
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.job-update-form .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
	border: none !important;
}

/* ============================ */

#company-create-page {
	display: flex;
	margin-top: 5rem;
	padding-inline: 15vw;
	height: calc(100vh - 5rem);
}

.posted-job-left-container {
	background-color: white;
	border: 1px solid #cbd1d7;
	width: 20vw;
	min-height: 70vh;
	height: calc(100vh - 5rem);
	overflow-y: auto;
	padding-bottom: 2rem;
}

.posted-job-right-container {
	width: 60vw;
}

.job-company-mobile-select,
.posted-job-left-container .posted-jobs-mobile,
.job-backbutton-mobile,
.email-tablet,
.button-section-mobile {
	display: none !important;
}

/* ============================== */

.posted-job-left-container .header-section {
	display: flex;
	justify-content: center;
	padding: 0.8rem;
	border-bottom: 1px solid #cbd1d7;
	position: fixed;
	z-index: 1;
	width: 20vw;
	background-color: white;
}

.posted-job-left-container .body-section {
	min-height: 70vh;
	margin-top:3.2rem ;
	margin-bottom:4rem;
}

.name-container p{
	overflow-wrap: anywhere;
}

/* RIGHT SIDE */

.posted-job-right-container .jobList-header-section {
	position: fixed;
    width: 50.5vw;
    z-index: 2;
}

.jobs-redirect {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 1.5rem !important;
}

.posted-job-right-container {
	border-radius: 0px;
	width: 53vw;
	margin-right: 0rem;
	border-right: 1px solid #cbd1d7;
	border-bottom: 1px solid #cbd1d7;
	overflow-x: hidden;
	overflow-y: auto;
	/* height: calc(100% - 3.5rem); */
}


.job-company-select {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 2%;
}

.no-jobPosted {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(0%, -60%);
}
.no-job-message {
	margin: 1rem 0;
	font-weight: bold;
	color: var(--subHeading-text-color);
}

.img-no-candidate{
	margin-top:10rem;
	margin-left: 30%;
	width: max-content;
	
}

.first-job-btn {
	background-color: var(--primary-color);
	color: white;
	font-weight: bold;
	padding: 0.5rem;
	border-radius: 5px;
	margin: 0.5rem 0;
}

.created-company {
	padding-bottom: 1rem;
	min-height: calc(100vh - 10rem);
}

/* Job details section */

.posted-job-right-container {
	background-color: rgba(69, 138, 240, 0.1);
}

.posted-details-tab {
	position: relative;
	padding: 1rem 2rem;
	margin-top: 3rem;
	height: calc(100% - 3.5rem);
	overflow: scroll;
}

.posted-details-tab .job-update-btn {
	position: absolute;
	top: 10%;
	right: 2%;
}

.job-details .header-section {
	display: flex;
	margin-bottom: 1rem;
}

.job-details .header-section span {
	display: flex;
	align-items: center;
	font-weight: bolder;
	margin-left: 1rem;
	font-size: 1.3rem;
}

.job-details .header-section img {
	height: 4rem;
	width: 4rem;
}

.job-details .title {
	margin: 0.5rem 0;
	/* color: var(--heading-text-color); */
	display: flex;
	justify-content: space-between;
}

.job-details .title p {
	font-weight: lighter;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
}

.job-details .location-flex {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 70%;
	font-size: 0.9rem;
	font-weight: 550;
	color: var(--subHeading-text-color);
}

.job-details .job-description {
	margin-top: 0.8rem;
	font-size: 0.8rem;
	word-wrap: break-word;
}

.job-details .job-description h4 {
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
}

.job-details .skills h4 {
	font-size: 0.9rem;
	margin: 0.8rem 0;
}

.job-details .skills ul {
	display: flex;
	flex-wrap: wrap;
}

.job-details .skills li {
	border-radius: 5px;
	margin-bottom: 0.3rem;
	font-size: 0.8rem;
	background-color: white;
	color: var(--primary-color);
	font-weight: bold;
	padding: 0.3rem 1.5rem;
}

/* Candidate Section */

.candidate-status-tab{
	margin-top:3.2rem;
	position: fixed;
	z-index: 1;
	width: 50.5vw;
}

.candidate-details{
	margin-top: 5.6rem;
}
.applied-candidate {
	padding: 1rem 0.5rem 1rem 0.5rem;
	background-color: rgba(69, 138, 240, 0.1);
	border-radius: 5px;
	/* min-height: 70vh; */
}

.candidate-upper-flex {
	display: flex;
	justify-content: space-between;
}

.candidate-upper-flex img {
	height: 4rem;
	width: 4rem;
	border-radius: 50%;
}

.candidate-upper-flex .candidate-name {
	font-weight: bolder;
	word-wrap: break-word;
	width: 70%;
}

/* .candidate-skill-section {
	width: 65%;
} */

.candidate-upper-flex .candidate-description {
	font-size: 0.8rem;
	display: flex;
	background: white;
	color: var(--subHeading-text-color);
	padding: 0 0.7rem;
	border-radius: 5px;
	padding: 0.5rem;
	word-break: break-word;
	min-height: 10rem;
}

.candidate-lower-flex {
	display: flex;
	justify-content: space-between;
}

/* .candidate-lower-flex  */
.candidate-skill {
	display: flex;
	flex-wrap: wrap;
}

/* .candidate-lower-flex  */
.candidate-skill li {
	border-radius: 5px;
	margin-bottom: 0.3rem;
	font-size: 0.8rem;
	background-color: white;
	color: var(--primary-color);
	/* font-weight: 600; */
	padding: 0.3rem 0.5rem;
	margin-right: 1rem;
	display: flex;
	white-space: nowrap;
}

.candidate .candiate-info-flex {
	width: 25%;
	margin-right: 10%;
}

.candidate-lower-flex span {
	font-size: 0.9rem;
	align-items: center;
	color: var(--primary-color);
	/* font-weight: bold; */
}

.candidate-lower-flex span .candidate-info candidate-info {
	margin-right: 0.5rem;
}

.candidate-lower-flex span .icons {
	color: var(--primary-color);
}

.limit-text {
	width: 100px;
	display: block;
	width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.job-post-mobile-back {
	display: none;
}

.next-step-container {
	min-height: 2rem;
	position: absolute;
	right: 0rem;
	min-width: 15rem;
	bottom: 2.8rem;
	padding: 0.8rem;
}

.next-step-container li {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
}

.next-step-mobile-container {
	bottom: 2rem;
	padding: 0.3rem !important;
}

.candidate-shortlist-btn {
	background-color: rgba(199, 255, 216, 0.45) !important;
}

.candidate-reject-btn {
	background-color: rgba(255, 238, 238, 0.7);
}

.interview-date-time input {
	width: 48% !important;
}

/* ============ dropdown =========================== */
.dropdown {
	position: relative;
	width: 30%;
	/* margin-bottom: 10rem; */
}

.dropdown input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	/* border: none; */
	outline: none;
	padding: 0.8rem;
	cursor: pointer;
	padding-left: 3rem;
}

.dropdown .options {
	position: absolute;
	top: 3.5rem;
	width: 100%;
	background: white;
	padding: 1rem;
	z-index: 100;
	color: var(--primary-color);
	font-weight: 600;
	border-radius: 10px;
}

.dropdown::before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	border: 2px solid #333;
	right: 15px;
	top: 20px;
	z-index: 10;
	border-top: #fff;
	border-right: #fff;
	transform: rotate(-45deg);
	pointer-events: none;
	transition: 0.5s;
}

.job-company-select-img {
	top: 0.8rem;
	left: 0.8rem;
}

.dropdown.closeOption::before {
	top: 25px;
	transform: rotate(-225deg);
}

.mobileSelect {
	display: none;
}

.mobile-job-heading {
	display: block;
}

/* ================ for menu============================= */
.postedJob-menu {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
/* ================================================== */
/* ================= MEDIA QUERY SECTION =========================== */
@media (min-width: 1201px) and (max-width: 1300px) {
	#company-create-page {
		display: flex;
		margin-top: 5rem;
		padding-inline: 10vw;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		height: calc(100vh - 5rem);
		width: 25vw;
		overflow-y: auto;
	}

	.posted-job-left-container .header-section {
		width:23vw !important;
	}

	.posted-job-left-container #resume-search {
		width:inherit !important;
	}

	.posted-job-right-container {
		width: 65vw;
	}
	.posted-job-right-container .jobList-header-section{
		width: 57.3vw;
	}

	.posted-job-right-container .candidate-status-tab{
		width:57.3vw !important;
	}

	.limit-text {
		width: 120px;
		display: block;
		width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#company-create-page {
		display: flex;
		margin-top: 5rem;
		padding-inline: 5vw;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		width: 25vw;
	}

	.posted-job-left-container .header-section {
		width:23vw !important;
	}

	.posted-job-left-container #resume-search {
		width:inherit !important;
	}


	.posted-job-right-container {
		width: 75vw;
	}

	.posted-job-right-container .jobList-header-section {
		width: 67vw;
	}

	.posted-job-right-container .candidate-status-tab{
		width:67vw !important;
	}

	.job-details .location-flex {
		width: 80%;
	}

	.posted-details-tab {
		padding: 1rem 1.5rem;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#company-create-page {
		display: flex;
		margin-top: 5rem;
		padding-inline: 1vw;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		width: 28vw;
	}

	.posted-job-left-container .header-section {
		width:28vw !important;
	}

	.posted-job-left-container #resume-search {
		width:inherit !important;
	}


	.posted-job-right-container {
		width: 70vw;
	}

	.posted-job-right-container .jobList-header-section {
		width: 69.4vw;
	}

	.posted-job-right-container .candidate-status-tab{
		width:69.4vw !important;
	}

	.job-details .location-flex {
		width: 80%;
	}

	.posted-details-tab {
		padding: 1rem 1.5rem;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#company-create-page {
		display: flex;
		margin-top: 5rem;
		padding-inline: 10vw;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		width: 20vw;
	}

	.posted-job-left-container .header-section {
		width:21vw !important;
	}


	.posted-job-right-container {
		width: 60vw;
	}

	.posted-job-right-container .jobList-header-section {
		width: 59.4vw;
	}

	.posted-job-right-container .candidate-status-tab{
		width:59.4vw !important;
	}

	.job-details .location-flex {
		width: 95%;
	}

	.posted-details-tab {
		padding: 1rem 1.5rem;
	}

	.candidate-skill-section {
		width: 60%;
	}

	.candidate-lower-flex .candiate-info-flex {
		width: 40%;
	}

	.candidate-info p {
		font-size: 0.7rem;
	}

	.candidate-upper-flex .shortlist-reject-next-btn{
		width:max-content !important;
	}

	/* .email-desktop {
		display: none;
	} */
	.email-tablet {
		display: block;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#company-create-page {
		display: flex;
		margin-top: 5rem;
		padding-inline: 2vw;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		width: 28vw;
	}

	.posted-job-right-container {
		width: 68vw;
	}

	.posted-job-left-container .header-section {
		width:28vw !important;
	}

	.posted-job-left-container #resume-search {
		width:inherit !important;
	}

	.posted-job-right-container .jobList-header-section {
		width: 67.4vw;
	}

	.posted-job-right-container .candidate-status-tab{
		width:67.4vw !important;
	}

	.job-details .location-flex {
		width: 95%;
	}

	.posted-details-tab {
		padding: 1rem;
	}

	/* .candidate-skill-section {
		width: 50%;
	} */

	.candidate-lower-flex .candiate-info-flex {
		width: 50%;
	}

	.candidate-info flex p {
		font-size: 0.9rem;
	}

	.job-company-select {
		right: -5%;
	}
	/* 
	.candidate-upper-flex .candidate-description {
		width: 60%;
	} */

	.candidate-info p {
		font-size: 0.7rem;
	}

	.candidate-upper-flex .shortlist-reject-next-btn{
		width:max-content !important;
	}

	/* .email-desktop {
		display: none;
	} */
	.email-tablet {
		display: block;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	.mobileSelect {
		display: block;
	}

	.mobile-job-heading {
		display: none;
	}

	#company-create-page {
		display: flex;
		margin-top: 4rem;
		padding-inline: 0vw;
	}

	.job-details .location-flex {
		width: 100%;
	}

	.posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 90vh;
		width: 100%;
	}

	.posted-job-left-container .header-section {
		padding: 0.2rem 0.5rem;
	}

	.posted-job-left-container .posted-jobs h3 {
		color: black;
	}

	.company-select {
		bottom: 0% !important;
	}

	.job-company-mobile-select {
		display: block !important;
		position: absolute;
		right: 5rem;
		width: 9rem !important;
	}

	.posted-job-left-container .header-section {
		justify-content: left !important;
		width: inherit;
	}

	.posted-job-left-container .header-section h3 {
		font-size: 1.3rem;
	}

	.job-details-mobile .job-description p {
		font-size: 0.8rem;
	}

	.job-mobile-title {
		font-size: 0.9rem !important;
		font-weight: 550 !important;
	}

	.job-post-mobile-back {
		display: block;
	}

	.job-update-btn{
		top:-2rem;
	}

	.posted-job-left-container .posted-jobs-mobile {
		display: flex !important;
		border-bottom: 1px solid #cbd1d7 !important;
		border-right: none;
		position: relative;
		border-left: none;
		border-top: none;
		margin: 0rem;
		text-align: left;
		padding: 0.5rem 0 !important;
		padding-left: 0.7rem !important;
		cursor: pointer;
	}

	.posted-job-right-container,
	.posted-job-left-container .posted-jobs {
		display: none;
	}

	.jobs-redirect{
		position: fixed !important;
		z-index: 1;
		background-color: white;
		width: 100%;
		top: 3rem;
		padding-top: 1rem;
	}
	.job-details-mobile .skills li {
		border-radius: 5px;
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
		font-size: 0.9rem;
		background-color: white;
		color: var(--primary-color);
		font-weight: 600;
		padding: 0.5rem 1rem;
	}

	.candidate-lower-flex-mobile span {
		font-size: 0.9rem;
		align-items: center;
		color: var(--primary-color);
		width: 100%;
	}

	.candidate-lower-flex-mobile .candidate-skill {
		display: flex;
		flex-wrap: wrap;
		width: 100% !important;
	}

	.candidate-lower-flex-mobile .candidate-skill li {
		border-radius: 5px;
		margin-bottom: 0.5rem;
		font-size: 0.8rem;
		background-color: white;
		color: var(--primary-color);
		padding: 0.3rem 0.5rem;
		margin-right: 0.5rem;
		display: flex;
		white-space: nowrap;
	}

	.main-job-detail-mobile {
		margin-bottom: 5rem;
	}

	.job-backbutton-mobile {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.button-section-mobile {
		display: flex !important;
	}

	.job-company-mobile {
		width: 60%;
	}

	.job-company-mobile h3 {
		font-size: 0.9rem;
		font-weight: 550;
	}

	.detailedJob-applicant {
		font-weight: normal;
	}

	.job-details-mobile{
		margin-top: -6rem !important;
	}

	.job-details-mobile .job-detailed-mobile {
		width: 170px;
	}

	.jobList-header-section{
		margin-bottom: 10rem;
	}

	.candidate-description-mobile {
		font-size: 0.8rem !important;
		line-height: 1.4rem !important;
		word-wrap: break-word;
	}

	.candidate-status-tab {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
		width: 100%;
		top: 3.6rem;
	
	}
}
@media (min-width: 0px) and (max-width: 450px) {
	#company-create-page {
		display: flex;
		margin-top: 4em;
	}

	.posted-job-right-container {
		width: 60vw;
	}
}
@media (min-width: 0px) and (max-width: 380px) {
	#company-create-page {
		display: flex;
		margin-top: 4rem;
		/* padding-inline: 15vw; */
	}

	/* .posted-job-left-container {
		background-color: white;
		border: 1px solid #cbd1d7;
		min-height: 70vh;
		width: 20vw;
	} */

	.posted-job-right-container {
		width: 60vw;
	}

	.candidate-info-mobile:first-child {
		margin-right: 0.5rem;
	}

	.job-details-mobile .job-detailed-mobile {
		display: block;
		width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.job-details-mobile .skills li {
		font-size: 0.8rem;
		padding: 0.3rem 0.5rem;
	}

	.job-mobile-title {
		font-size: 0.85rem !important;
		font-weight: 600 !important;
	}

	.mobile-job-location {
		flex-wrap: wrap;
	}

	.applied-candidate-email-limit {
		display: block;
		width: 180px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

@media screen and (max-width:640px) {
	.mobile-search-view{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-70%);
	}	
	.mobile-candidate-details{
		background-color: rgba(69, 138, 240, 0.1);
	}
}
