/* INVESTOR DETIALED */

.mobile-investment-section,
.mobile-recentInvestment-section {
	display: none;
}

.detailedInvestor-overall-detail {
	background-color: white;
}

.detailedInvestor-detail-section {
	padding: 28px 25px 10px 25px;
}

.detailedInvestor-connect-btn {
	outline: none;
	border: none;
	background-color: var(--primary-color);
	color: white;
	font-weight: bold;
	border-radius: 5px;
	padding: 0.2rem 0.8rem;
	position: absolute;
	right: 5%;
	top: 50%;
	padding: 0.5rem;
}

/* .detailedInvestor-industry-section, */
.detailedInvestor-investment-section,
.detailedInvestor-lastInvestment-section {
	background-color: var(--primary-lightBlue-color);
	margin-bottom: 1.5rem;
	padding: 24px 30px;
	border-radius: 5px;
}

.detailedInvestor-detail-section > ul {
	display: flex;
	justify-content: space-between;
}

.investorDetails-first-li {
	display: flex;
	min-width: 70%;
}

.investorDetail-overall {
	width: 75%;
	min-width: 75%;
}

.detailedInvestor-detail-section h4 {
	font-size: 25px;
	font-weight: 600;
}
/* 
.investor-details-name{
	word-wrap: break-word;
} */

.detailedInvestor-detail-section > span {
	font-size: 15px;
}

.detailedInvestor-investment-table {
	width: 100%;
}

.detailedInvestor-investment-table tr th {
	font-size: 12px;
	color: var(--subHeading-text-color);
	font-weight: 550;
	padding-bottom: 1rem;
}

.detailedInvestor-investment-table tr td {
	font-size: 14px;
	font-weight: 600;
	color: var(--primary-color);
	text-align: center;
}

.detailedInvestor-lastInvestment-section h4 {
	font-size: 14px;
	font-weight: 600;
}

.detailedInvestor-industry-section ul {
	display: flex;
}

.detailedInvestor-industry-section ul li {
	margin-right: 1rem;
	color: var(--primary-color);
}

.detailedInvestor-industry-section ul i {
	display: block;
	text-align: center;
}

.detailedInvestor-industry-section ul i img {
	height: 2rem;
	width: 2rem;
}

.detailedInvestor-industry-section li span {
	font-size: 12px;
	font-weight: 600;
}

.detailedInvestor-recentInvest-table tr th {
	font-size: 12px;
	font-weight: 550;
}

.detailedInvestor-recentInvest-table tr td {
	font-size: 12px;
	text-align: center;
	font-weight: 600;
}

.detailedInvestor-recentInvest-table {
	width: 100%;
}

.detailedInvestor-recentInvest-table img {
	height: 3rem;
	width: 3rem;
}

/* ============================= */
#three-section-investorDetails-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
	margin-bottom: 5rem;
}

#three-section-investorDetails-container {
	display: flex;
	justify-content: space-between;
}

#three-section-investorDetails-container .left-container {
	width: 12vw;
}
#three-section-investorDetails-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-investorDetails-container .right-container {
	width: 12vw;
}

.lead-investor-checkbox {
	width: 5% !important;
}

.investorDetail-overall > span {
	justify-content: unset !important;
}

.detailedInvestor-detail-section h4 {
	max-width: 65%;
	margin-right: 0.5rem;
	word-wrap: break-word;
}

/* ============================================ */

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-investorDetails-container {
		padding-inline: 10vw;
	}

	#three-section-investorDetails-container .left-container,
	#three-section-investorDetails-container .right-container {
		width: 15vw;
	}

	#three-section-investorDetails-container .mid-container {
		width: 46vw;
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-investorDetails-container {
		padding-inline: 5vw;
	}

	#three-section-investorDetails-container .left-container,
	#three-section-investorDetails-container .right-container {
		width: 17.5vw;
	}

	#three-section-investorDetails-container .mid-container {
		width: 50vw;
	}
}

@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-investorDetails-container {
		padding-inline: 1vw;
	}

	#three-section-investorDetails-container .left-container,
	#three-section-investorDetails-container .right-container {
		width: 20vw;
	}

	#three-section-investorDetails-container .mid-container {
		width: 54vw;
	}
}

@media (min-width: 850px) and (max-width: 949px) {
	#three-section-investorDetails-container {
		padding-inline: 10vw;
	}

	#three-section-investorDetails-container .left-container {
		width: 25vw;
	}

	#three-section-investorDetails-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-investorDetails-container .right-container {
		display: none;
	}
}

@media (min-width: 641px) and (max-width: 849px) {
	#three-section-investorDetails-container {
		padding-inline: 2vw;
	}

	#three-section-investorDetails-container .left-container {
		width: 24vw;
	}

	#three-section-investorDetails-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-investorDetails-container .right-container {
		display: none;
	}

	.publicInvestor-mix-section h5 {
		font-size: 0.9rem;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	.investorInvestment-desktop {
		display: none;
	}

	#three-section-investorDetails-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
	}

	#three-section-investorDetails-container {
		display: block;
	}

	#three-section-investorDetails-container .right-container,
	#three-section-investorDetails-container .left-container {
		display: none;
	}

	#three-section-investorDetails-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.detailedInvestor-investment-table,
	.detailedInvestor-recentInvest-table {
		display: none;
	}

	.mobile-investment-section {
		display: flex;
	}

	.mobile-recentInvestment-section {
		display: block;
	}

	.mobile-investment-section .heading {
		font-size: 1rem;
		font-weight: 550;
	}

	.investment-value {
		font-size: 0.8rem;
	}

	.detailedInvestor-detail-section h4 {
		font-size: 0.9rem;
		font-weight: 600;
		/* line-height: 1rem; */
		word-wrap: break-word;
	}

	.investorDetail-overall {
		line-height: 1.4rem;
	}

	.detailedInvestor-detail-section span {
		font-size: 0.75rem;
	}

	.mobile-recentInvestment-section img {
		position: relative;
		left: 50%;
		transform: translate(-50%);
	}

	.investorDetail-name {
		line-height: 1rem;
	}

	.recent-investment-mobile .heading {
		font-size: 0.8rem;
		font-weight: 600;
	}

	.investor-profile-section {
		flex-direction: row !important;
		justify-content: start !important;
		margin-left: 1rem;
		margin-top: 4rem;
		width: 90vw !important;
	}

	.investor-profile-section {
		padding-top: 1rem;
	}

	.investor-profile-section img {
		margin-right: 0.2rem;
	}

	.investor-details {
		display: block !important;
		padding: 0 !important;
	}

	.investor-details p {
		width: 95vw !important;
	}

	.investorDetailed-img {
		margin-right: 0.5rem !important;
		height: 3rem !important;
		width: 3rem !important;
	}

	.detailedInvestor-connect-btn {
		outline: none;
		border: none;
		background-color: var(--primary-color);
		color: white;
		font-weight: 550;
		border-radius: 5px;
		padding: 0.2rem 0.5rem;
		position: absolute;
		right: 1%;
		top: 2rem;
	}
	.investorDetails-view {
		position: absolute;
		/* right: 3rem; */
		margin-top: 0.3rem;
	}

	.publicInvestor-mix-section ul li p,
	.detailedInvestor-industry-section ul li p {
		font-size: 0.8rem;
	}

	.investorDetail-website {
		font-size: 0.9rem;
		font-weight: 500 !important;
	}

	.publicInvestor-mix-section .about-description {
		font-size: 0.8rem;
		line-height: 1.3rem;
	}

	.publicInvestor-mix-section > p {
		margin-bottom: 0.7rem;
	}

	.publicInvestor-mix-section {
		padding: 5px 10px 10px 10px;
	}

	.recent-investment-mobile {
		background-color: white;
		padding: 0.7rem;
		margin-inline: 0.5rem;
		border-radius: 5px;
	}

	.user-investor-details .user-investor-description {
		font-weight: normal !important;
		font-size: 0.8rem !important;
		margin-right: 0.5rem;
	}

	.investor-view-profil {
		margin-top: 0.25rem;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-investorDetails-container {
		padding-inline: 0vw;
	}

	#three-section-investorDetails-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-investorDetails-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	.common-investorDetails-section {
		padding: 0.5rem;
	}
}

@media (min-width: 0px) and (max-width: 375px) {
	.detailedInvestor-investment-table tr th {
		font-size: 12px;
		color: var(--subHeading-text-color);
		font-weight: 550;
		padding-bottom: 1rem;
		text-align: center;
	}

	.mobile-investment-section .heading {
		font-size: 0.8rem;
		font-weight: 550;
	}

	.recent-investment-mobile span {
		font-size: 0.8rem;
	}

	.mobile-recentInvestment-section,
	.mobile-investment-section {
		padding: 0.3rem !important;
	}

	.detailedInvestor-connect-btn {
		font-size: 0.8rem;
	}

	.investorDetails-view {
		position: absolute;
		right: 2%;
		top: 0.8rem;
	}

	.investor-connection-btn {
		top: 2rem !important;
	}

	.detailedInvestor-detail-section h4 {
		max-width: 50%;
		margin-right: 0.5rem;
		word-wrap: break-word;
	}
}
