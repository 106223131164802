.forgot-container input {
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	font-size: 0.9rem;
	display: block;
	outline: none;
	border: none;
	border: 1px solid silver;
}

.forgot-container .heading {
	font-size: 1.5rem;
}

.forgot-container .heading {
	text-align: center;
	margin-bottom: 1rem;
}

.forgot-container {
	width: 25rem;
}

.change-pass-user-info {
	width: 70% !important;
}

@media (min-width: 0px) and (max-width: 640px) {
	.forgot-container {
		width: 90vw;
	}
	.confirm-pass-container {
		display: block !important;
		margin-bottom: 10rem;
	}
	/* .confirm-pass-container img {
		display: none;
	}  */

	.confirm-pass-container img,
	.confirm-pass-container form,
	.confirm-pass-container form input {
		width: 100%;
	}

	.confirm-pass-container form button {
		margin-right: 0 !important;
	}

	.confirm-pass-container .confirm-pass-div {
		margin-bottom: 10rem;
	}

	.confirm-pass-leftContainer {
		margin-bottom: 10rem !important;
	}
}
