#two-section-createdInvestor-container {
	display: flex;
	margin-top: 2rem;
	margin-bottom: 1rem;
	padding-left: 15vw;
	padding-right: 15vw;
}

.user-leftContainer {
	width: 52vw;
	border-radius: 10px;
	background-color: white;
	margin-right: 3vw;
}

.user-rightContainer {
	/* border: 1px solid black; */
	width: 15vw;
}

/* Create investor Form */

/* .investor-left {
	width: 15vw;
	margin-right: 3vw;
	border: 1px solid black;
} */
/* .investor-right {
	width: 50vw;
	background-color: white;
	border-radius: 10px;
	padding: 1rem;
} */

.investor-right .investor-details {
	display: flex;
}

.investor-right .investor-details img {
	border-radius: 50%;
}

.investor-right .investor-details>p {
	margin-left: 1rem;
	font-size: 0.9rem;
	margin-top: 1rem;
	font-weight: 550;
	word-wrap: break-word;
}

.investor-right .investor-common-div {
	background-color: var(--primary-lightBlue-color);
	padding: 1rem;
	border-radius: 5px;
	margin-top: 1rem;
	color: var(--primary-color);
	font-weight: 550;
	position: relative;
}

.investor-right .investor-common-div h3 {
	margin-bottom: 0.7rem;
	color: black;
}

.investor-right .investor-common-div .description {
	text-align: left;
	font-size: 0.73rem;
	word-wrap: break-word;
}

.investor-common-list {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.investor-common-list li {
	font-size: 0.9rem;
}

.investor-right .button-design {
	margin-right: 1rem;
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
	padding: 0.2rem 0.5rem;
	margin-bottom: 0.8rem;
}

/* .investor-right .button-design li img {
	position: relative;
	left: 50%;
	transform: translate(-50%);
} */

.investor-right .investor-investment ul {
	display: flex;
}

.investor-right .investor-investment ul h5 {
	color: black;
}

.investor-right .investor-investment ul span {
	display: flex;
	justify-content: center;
}
.investor-right .investor-details-table {
	width: 100%;
}

.investor-right .investor-details-table tr th {
	width: 18%;
	text-align: left;
}

.investor-right .investor-details-table tr td {
	width: 18%;
	color: black;
}

.investor-right .website a {
	color: var(--primary-color);
	font-weight: 550;
}

.common-edit-btn {
	position: absolute;
	top: 10%;
	right: 2%;
}

.common-add-btn {
	position: absolute;
	top: 10%;
	right: 2%;
}

.investor-common-div .update-form input {
	border: none;
}

.investor-add-section .investor-redirect-btn {
	outline: none;
	border: none;
	font-weight: 550;
	padding: 0.7rem 0.3rem;
	border-radius: 5px;
	background-color: #eff3f9;
	box-shadow: var(--profile-card-shadow);
	/* display: none; */
}

.read-more-btn {
	font-weight: 600;
	cursor: pointer;
	margin-left: 0.3rem;
	font-size: 14px;
	display: inline !important;
	color: gray;
}

.investor-update-form .input {
	border: 1px solid silver;
}

/* INVESTOR CREATE FORM  */
.investor-error {
	color: red;
	font-size: 0.9rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.mobile-createdInvestorDetails-section,
.investor-title-mobile {
	display: none;
}

.investor-details h3 {
	display: block;
	width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* ================= MEDIA QUERY SECTION =========================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#two-section-createdInvestor-container {
		padding-inline: 10vw;
	}

	.user-leftContainer {
		width: 65vw;
	}

	.user-rightContainer {
		width: 15vw;
	}

	.create-company-section {
		width: 80vw;
		margin-inline: 10vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#two-section-createdInvestor-container {
		padding-inline: 5vw;
	}

	.user-leftContainer {
		width: 75vw;
	}

	.user-rightContainer {
		width: 15vw;
	}

	.create-company-section {
		width: 90vw;
		margin-inline: 5vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#two-section-createdInvestor-container {
		padding-inline: 1vw;
	}

	.user-leftContainer {
		width: 79vw;
	}
	.user-rightContainer {
		width: 19vw;
	}

	.create-company-section ul {
		width: 60%;
	}

	.create-company-section {
		width: 98vw;
		margin-inline: 1vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#two-section-createdInvestor-container {
		padding-inline: 10vw;
		display: block;
	}
	.user-leftContainer {
		width: 80vw;
	}

	.user-rightContainer {
		display: none;
	}

	.create-company-section {
		width: 80vw;
		margin-inline: 10vw;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#two-section-createdInvestor-container {
		padding-inline: 2vw;
		display: block;
	}
	.user-leftContainer {
		width: 96vw;
	}

	.user-rightContainer {
		height: 20rem;
		display: none;
	}

	.create-company-section {
		width: 96vw;
		margin-inline: 2vw;
	}

	.hero-section {
		display: block !important;
	}

	.hero-section ul {
		display: grid;
		grid-template-columns: 2fr 2fr;
		grid-column-gap: 2rem;
	}

	.hero-section ul li:last-child {
		position: relative;
		left: 50%;
		transform: translate(-10%);
		width: 120%;
	}

	.company-sample-img {
		display: flex;
		justify-content: center;
		height: 20rem;
	}

	.hero-section p,
	.hero-section h2 {
		font-size: 0.9rem !important;
	}

	.create-company-section ul li {
		padding: 0.5rem;
	}

	.create-company-section ul li img {
		height: 4rem;
		width: 6rem;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	#two-section-createdInvestor-container {
		padding-inline: 0;
		display: block;
		margin-top: 1rem;
		margin-bottom: 5rem;
	}

	.user-rightContainer {
		display: none;
	}

	.user-leftContainer {
		margin-bottom: 0rem !important;
	}

	.company-flex-section {
		display: block;
		width: 100%;
	}

	.create-company-section {
		width: 98vw;
		margin-inline: 1vw;
	}

	.user-investor-description {
		margin-left: 1rem !important;
	}
	.company-overview-section {
		position: relative;
	}

	.company-flex-section .first-list,
	.company-flex-section .second-list {
		width: 100%;
	}

	.company-flex-section .second-list {
		padding-left: 0rem;
	}

	.teams-member-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.company-member-section {
		width: 94%;
		margin-left: 2%;
		margin-right: 2%;
	}

	.company-mobile-flex {
		display: flex;
		align-items: center;
	}

	.investor-details h3 {
		text-align: left !important;
	}

	.company-mobile-flex h4 {
		display: flex;
		align-items: center;
		width: 50%;
	}

	.company-mobile-flex p {
		width: 50%;
	}

	.posted-jobs-flex .first-li {
		width: 40%;
		margin-bottom: 0.5rem;
	}

	.view-applicant {
		display: none !important;
	}

	.view-applicant-mobile {
		display: flex;
		font-size: 0.7rem;
	}

	.company-about-section,
	.company-flex-section,
	.map-section,
	.vlog-section,
	.posted-jobs-info-section {
		padding-left: 1rem;
	}

	.recent-post-heading {
		margin-left: -1rem;
	}

	.company-address-section {
		margin-left: 1rem;
	}

	.posted-jobs-flex {
		display: block;
	}

	.posted-jobs-flex .first-li {
		width: 100%;
		padding-left: 0.3rem;
		padding-right: 0.3rem;
	}

	/* .posted-jobs-flex .second-li {
		width: 100%;
		padding-left: 3.5rem;
		padding-right: 0.3rem;
	} */

	#slick-div img {
		position: relative !important;
		left: 50% !important;
		transform: translate(-55%) !important;
		height: 10rem;
		width: 16rem;
	}

	.create-company-section {
		margin-top: 2rem;
	}

	.hero-section {
		display: block !important;
	}

	.create-company-section li p,
	.create-company-section li h2,
	.create-company-section .main-line {
		font-size: 0.9rem;
	}

	.mobile-createdInvestorDetails-section {
		display: block;
	}

	.investor-details-table {
		display: none;
	}

	.investor-details img {
		height: 3rem;
		width: 3rem;
	}

	.investor-title-mobile {
		display: block;
	}

	.userInvestor-description {
		width: 100% !important;
	}

	.investor-right .button-design {
		margin-right: 1rem;
		box-shadow: var(--profile-card-shadow);
		border-radius: 5px;
		padding: 0.3rem 0.3rem;
		margin-bottom: 0.5rem;
	}
	.investor-common-div li {
		font-size: 0.8rem;
	}

	.investor-common-div li img {
		height: 1.5rem;
		width: 1.5rem;
	}
}

@media (min-width: 0px) and (max-width: 450px) {
	.company-about-section,
	.company-flex-section,
	.map-section,
	.vlog-section {
		padding: 0.5rem 1rem;
	}

	.company-address-section {
		margin: 0.5rem 1rem;
	}

	.company_name {
		margin-left: 1rem !important;
	}

	.company-about-section .overview,
	.vlog-section p,
	.recent-posts p {
		font-size: 0.8rem;
	}

	.created-company .company-img {
		height: 4rem;
		width: 4rem;
		top: 75%;
	}

	.company_name {
		margin-top: 3rem !important;
	}

	.posted-jobs-info-section {
		padding: 0.5rem 0;
	}

	.posted-jobs-flex img {
		height: 3rem;
		width: 3rem;
	}

	.company-socialmedia {
		display: flex;
		margin-top: 0rem;
	}

	.companySocial-edit-btn {
		top: 20%;
	}

	.company-sample-img {
		height: 15rem;
	}
}

@media (min-width: 0px) and (max-width: 380px) {
	.company-form {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
	}

	.company-socialmedia li img {
		margin-right: 0.7rem;
		height: 1.2rem;
		width: 1.2rem;
	}

	.created-company .company-redirect {
		width: 100%;
		justify-content: space-around;
		margin-top: 0.5rem;
	}

	.created-company .company-redirect li {
		padding: 0.5rem 0;
		margin: 0;
	}

	.posted-jobs-flex img {
		height: 2.5rem;
		width: 2.5rem;
	}

	.company-sample-img {
		height: 12rem;
	}

	.company-sample-img img {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		margin-top: -1rem;
	}
}

@media (max-width: 380px) {
	.investor-details h3 {
		font-size: 0.9rem;
	}
	.investor-title-mobile {
		font-size: 0.8rem;
	}

	.investor-title-mobile,
	.investor-details h3 {
		display: block;
		width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

@media (max-width: 300px) {
	.company-socialmedia li img {
		margin-right: 0.7rem;
		height: 1rem;
		width: 1rem;
	}

	.company-sample-img {
		height: 10rem;
	}
}
