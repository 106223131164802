.card-container {
  margin: 3rem 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.subscription-card {
  background-color: white;
  padding: 0.5rem;
  width: 16rem;
  height: fit-content;
  border-radius: 10px;
  margin: 1rem 0.5rem;
}

.learn-more-btn {
  background-color: inherit;
  padding: 0.5rem;
  width: 100vw;
  color: #619bf2;
  border: 1px solid #619bf2;
  border-radius: 20px;
  justify-content: center;
}

.learn-more-btn:hover {
  background-color: #619bf2;
  color: white;
}

.learn-more-btn-disable{
  color: #64748b;
  border: 1px solid #64748b;
}

.learn-more-btn-disable:hover{
  background-color: white;
  color:#64748b;
}