* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}

.login-company-logo-mobile {
	display: none;
}

#list li {
	list-style: inside !important;
}

.login-container {
	height: auto;
	margin-top: 25vh;
	margin-bottom: 25vh;
	width: auto;
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.login-form {
	height: auto;
	display: inline-block;
	padding: 2rem 1rem;
}

.login-form h1 {
	text-align: center;
	margin-bottom: 1rem;
}

.login-form label {
	display: block;
	font-size: 0.8rem;
	font-weight: bold;
	padding-left: 0.5rem;
	margin-top: 1rem;
}

.login-form input,
.new-password-form input {
	display: block;
	width: 20rem;
	padding: 0.5rem;
	border-radius: 5px;
	outline: none;
	border: 1px solid silver;
	margin-top: 0.3rem;
}

.new-password-form {
	width: 20rem;
}
.new-password-form input {
	width: 100% !important;
}

input.error {
	border-color: red;
}

.input-feedback {
	font-size: 0.875rem;
	padding-bottom: 0.3rem;
	color: red;
	font-weight: 600;
}

.login-btn {
	margin-top: 1rem;
	display: block;
	outline: none;
	border: none;
	color: white;
	font-weight: bolder;
	background: #0a66c2;
	width: 20rem;
	padding: 0.6rem 0;
	font-size: 1rem;
	border-radius: 5px;
}

.login-btn:hover {
	background-color: #2867b2;
}

.g-btn {
	width: 60% !important;
	color: red !important;
}

.google-btn span {
	font-weight: bold !important;
	color: var(--subHeading-text-color);
	font-size: 1rem !important;
}

.google-btn {
	box-shadow: none !important;
	border: 0.1px solid silver !important;
	border-radius: 5px !important;
	display: flex !important;
	justify-content: center !important;
}

.login-form .google-btn,
.login-form .linkedin-btn {
	width: 20rem;
}

.registration-form .google-btn,
.registration-form .linkedin-btn {
	width: 100% !important;
}

/* .landing-page-form .google-btn,
.landing-page-form .linkedin-btn {
	width: 60% !important;
} */

.login-form a {
	text-decoration: none;
	color: black;
	font-size: 0.8rem;
}

.login-form h2 {
	text-align: center;
	color: #0a66c2;
	position: relative;
	font-size: 1rem;
}

.forgot {
	margin: 0.5rem 0 0.2rem 0;
}

.forgot span {
	font-size: 1rem;
	font-weight: bold;
	padding: 0.3rem 0.5rem;
}

.forgot:hover {
	text-decoration: underline;
	transition: all 0.3s ease;
	border-radius: 5px;
}

.error {
	color: red;
	/* font-weight: 500; */
	font-size: 0.95rem;
}

.signin {
	color: black;
	padding: 1rem;
	font-size: 0.9rem;
}

.signin span {
	color: var(--primary-color);
	font-weight: bold;
}

.signin span:hover {
	text-decoration: underline;
}

@media screen and (min-width: 640px) and (max-width: 849px) {
	.login-form input,
	.login-btn,
	.login-form .google-btn,
	.login-form .linkedin-btn {
		width: 50vw;
	}
}

@media (min-width: 0px) and (max-width: 639px) {
	.login-company-logo-mobile {
		display: block;
	}

	.login-company-logo {
		display: none;
	}

	#navbar ul li {
		width: auto !important;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	.login-container {
		margin-top: 6rem;
	}
}
@media (min-width: 0px) and (max-width: 350px) {
	.login-form input,
	.login-btn,
	.login-form .google-btn,
	.login-form .linkedin-btn,
	.new-password-form input,
	.new-password-form {
		width: 15rem !important;
	}

	.login-company-logo {
		display: none;
	}
}
