/* UPDATE DESIGN CSS */

#user-profile {
	display: flex;
	margin-top: 5rem;
	padding-left: 15vw;
	padding-right: 15vw;
}

#info-section {
	height: auto;
	border-radius: 10px;
	box-shadow: 0.1px 0.1px 5px 0px gray;
	background-color: white;
	width: 100%;
}

.user-leftContainer {
	width: 52vw;
	border-radius: 10px;
	background-color: white;
}

.user-rightContainer {
	width: 15vw;
	position: fixed;
	right: 15vw;
}

/* NEW added */
.flex-container {
	display: flex;
}

.flex-container > li {
	width: 45%;
	margin: 0.5rem;
}

.flex-container > li:first-child,
.flex-container > li:last-child {
	width: 25%;
}

#education,
#experience,
#project,
#attachments,
#language,
#certificate,
.userAbout-section,
.userSkills-section,
.socialMedia-section {
	min-height: 10rem;
	max-height: fit-content;
}

/* PROFILE SECTION */

.user-info {
	width: 60%;
	height: fit-content;
}

.user-info .userName {
	margin-top: 0.8rem;
	color: var(--heading-text-color);
	font-size: clamp(16px, 2vw, 24px);
	word-wrap: break-word;
}

.user-profile img {
	height: 8rem;
	width: 7.8rem;
	border-radius: 15px;
	object-fit: fill;
	margin: 1rem 1.5rem 1rem 2rem;
}

.profileimage-edit-btn {
	position: absolute;
	top: 7.5rem;
	right: 2rem;
	cursor: pointer;
	color: #4179c8;
	transition: all 0.2s ease;
}

/* .image-preview { */
.profile-preview {
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-bottom: 1.5rem;
}

.profile-preview img {
	height: 20rem;
	width: 20rem;
	object-fit: contain;
}

.bg-preview {
	width: 40rem;
	height: 10rem;
	position: relative;
	left: 50%;
	transform: translate(-50%);
	object-fit: cover;
}

.alert-height {
	height: 2rem !important;
	width: 20rem !important;
}

@media screen and (max-width: 1600px) {
	.bg-preview {
		width: 550px;
	}
}

.imageDeactive {
	display: none;
}

#user-info-section {
	position: relative;
	/* height: 12rem; */
}

#user-info-section .user-details {
	display: flex;
	position: relative;
	top: 0%;
}

.userData {
	color: var(--subHeading-text-color);
}

.user-contact-details ul {
	display: flex;
}

.user-contact-details ul li {
	min-width: 8rem;
	padding-bottom: 0.3rem;
	font-size: 0.9rem;
	cursor: default;
	align-items: center;
	min-width: 9rem;
}

.user-contact-details ul li i {
	color: #0a66c2;
	font-size: 1rem;
}

.user-contact-details ul:first-child li:last-child .user-email {
	position: relative;
	top: -10%;
}

.userProfession {
	/* font-size: 1rem; */
	font-size: clamp(14px, 2vw, 16px);
	margin-bottom: 0.5rem;
}

.user-editSection {
	display: flex;
}

.edit-user-button {
	color: gray;
	cursor: pointer;
	margin-top: 1.2rem;
}

.userinfo-edit {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.userinfo-edit input {
	display: block;
	margin-bottom: 0.3rem;
	outline: none;
}

.userinfo-edit label {
	font-weight: bold;
}

.location-section ul {
	display: flex;
	justify-content: space-between;
}

.location-section li {
	width: 100%;
}

.user-save {
	outline: none;
	border: none;
	color: white;
	font-weight: bold;
	padding: 0.2rem;
	background-color: #d3d8e0;
	border-radius: 5px;
}

.backgroundimage-edit-btn {
	position: absolute;
	top: 10%;
	right: 2%;
	border-radius: 50%;
	padding: 0.1rem 0.3rem;
	color: #4179c8;
}

.details-data {
	font-size: var(--details-text-size);
	color: var(--details-text-color);
}

.about-project,
.about-education,
.about-experience {
	overflow-wrap: anywhere;
	line-height: 1.2rem;
}

/* FIRST ROW SECTION */

.first-row-section,
.second-row-section,
.third-row-section {
	/* display: flex; */
	/* padding: 1rem 1rem; */
	position: relative;
	display: inline;
	/* width: 17vw; */
}

.first-row-section > li,
.second-row-section > li,
.third-row-section > li {
	margin: 0 1%;
	padding: 0.5rem;
}

/* SOCIAL MEDIA SECTION */

#social-media-mobile {
	display: none;
}

.socialMedia-section {
	position: relative;
	height: auto;
}

.socialAdd-btn {
	position: absolute;
	top: 10%;
	right: 7%;
	color: gray;
}

.social-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
}

.userSocial-edit-btn {
	position: absolute;
	top: 1rem;
	right: 3%;
	color: gray;
}

.user-socialmedia {
	margin-top: 1rem;
	display: inline-block;
}

.user-socialmedia li {
	margin-bottom: 0.5rem;
	display: flex;
	flex-direction: row;
}

.user-socialmedia li i {
	margin-right: 1rem;
	font-size: 1.1rem;
}

.user-socialmedia li span {
	color: black;
}

/* ABOUT SECTION */

.userAbout-section {
	position: relative;
	padding: 1rem;
	height: auto;
}

#about p {
	margin-top: 1rem;
	color: var(--details-text-color);
	word-wrap: break-word;
}

.about-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
}

.details-data-big {
	font-size: var(--details-text-bigSize);
}
.aboutAdd-btn {
	position: absolute;
	top: 7%;
	right: 3%;
	color: gray;
}

.aboutEdit-btn {
	position: absolute;
	top: 1rem;
	right: 0.5rem;
	color: gray;
}

/* SKILLS SECTION */

.userSkills-section {
	position: relative;
	height: auto;
}

.userSkills-section .header {
	margin-bottom: 1rem;
	color: var(--heading-text-color);
}

.skills-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
}

.skills-content {
	display: inline-block;
	margin-right: 0.5rem;
	margin-bottom: 1rem;
	font-size: 12px;
}

.contents {
	display: flex;
	align-items: center;
}

.skills-content .contents {
	display: flex;
	border-radius: 5px;
	padding: 0.2rem;
	margin-bottom: 0.3rem;
	box-shadow: var(--profile-card-shadow);
	background-color: var(--profile-card-color);
	padding: 0.3rem 0.3rem 0.3rem 0.6rem;
}

.skillsAdd-btn,
.certificateAdd-btn,
.languageAdd-btn,
.attachmentAdd-btn {
	position: absolute;
	top: 1rem;
	right: 1%;
	color: gray;
}

.skills-delete-btn {
	display: flex;
	align-items: center;
}

/* Language section */

.UserLanguage-section {
	position: relative;
	height: auto;
}

.userLanguage-section .header {
	margin-bottom: 1rem;
	color: var(--heading-text-color);
}

.language-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
}

.language-content {
	display: inline-block;
	margin-right: 0.5rem;
	margin-bottom: 1rem;
	font-size: 12px;
}

.contents {
	display: flex;
	align-items: center;
}

.language-content .contents {
	display: flex;
	border-radius: 5px;
	padding: 0.2rem;
	margin-bottom: 0.3rem;
	box-shadow: var(--profile-card-shadow);
	background-color: var(--profile-card-color);
	padding: 0.3rem 0.3rem 0.3rem 0.6rem;
}

.language-delete-btn {
	display: flex;
	align-items: center;
}
/* SECOND ROW */
/* EXPERIENCE SECTION */

#experience {
	position: relative;
}

.experienceAdd-btn {
	position: absolute;
	top: 18%;
	right: 1%;
	color: gray;
}

.exp-header {
	margin-bottom: 1rem;
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
}

.header-section {
	display: flex;
	position: relative;
}

/* .experience-solo-btn {
	position: absolute;
	right: 1%;
	top: 20%;
} */

.exp {
	padding: 0.7rem 0.8rem;
	display: flex;
	margin-bottom: 1.5rem;
	background-color: var(--profile-card-color);
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
}
.exp-image img {
	height: 3rem;
	width: 3rem;
	margin-right: 1rem;
	object-fit: contain;
}

.exp-info,
.edu li:nth-child(2),
.project-section {
	width: 70%;
	line-height: 1rem;
}

.exp .user-company-name {
	font-weight: 550;
}

.exp .user-company-title {
	font-weight: bolder;
	font-size: var(--subHeading-text-size);
	color: var(--subHeading-text-color);
	margin-bottom: 0.1rem;
	line-height: 1.2rem;
}

.experienceEdit-btn {
	display: flex;
	color: gray;
	position: absolute;
	right: 2%;
}

/* LANGUAGE SECTION */
.language-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
	margin-bottom: 1rem;
}

.languages {
	display: flex;
}

.languages p {
	margin-right: 1rem;
	padding: 0.4rem;
	background-color: var(--profile-card-color);
	border-radius: 5px;
	display: flex;
	box-shadow: var(--profile-card-shadow);
}

/* EDUCATION SECTION */
#education {
	position: relative;
}

.educationAdd-btn {
	position: absolute;
	top: 18%;
	display: flex;
	align-items: center;
	right: 1%;
	color: gray;
}

.edu-header {
	margin-bottom: 1rem;
	color: var(--heading-text-color);
	font-size: var(--heading-text-size);
}

.edu {
	padding: 0.7rem 0.8rem;
	display: flex;
	margin-bottom: 1.5rem;
	background-color: var(--profile-card-color);
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
	line-height: 1rem;
}

.edu-image img {
	height: 3rem;
	width: 3rem;
	margin-right: 1rem;
	object-fit: contain;
}

.edu .user-school {
	font-weight: bolder;
	font-size: var(--subHeading-text-size);
	color: var(--subHeading-text-color);
	margin-bottom: 0.3rem;
	line-height: 1.2rem;
}

.user-field {
	margin-bottom: 0.2rem;
	font-weight: 600;
}

.educationEdit-btn {
	color: gray;
	position: absolute;
	right: 1%;
}

/* PROJECT SECTION */

#project {
	position: relative;
}

.project {
	display: flex;
	font-size: 12px;
	margin-bottom: 1.5rem;
	background-color: var(--profile-card-color);
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
	padding: 0.7rem 0.8rem;
}

.projectAdd-btn {
	position: absolute;
	top: 18%;
	right: 1%;
	color: gray;
}

.project-header {
	margin-bottom: 1rem;
	color: var(--heading-text-color);
	font-size: var(--heading-text-size);
}

.project-name {
	font-weight: bolder;
	font-size: var(--subHeading-text-size);
	color: var(--subHeading-text-color);
	margin-bottom: 0.3rem;
	line-height: 1.2rem;
}

.projectEdit-btn {
	color: gray;
	position: absolute;
	right: 1%;
	font-size: 1rem;
}

.poject-image {
	height: 3rem;
	width: 3rem;
	margin-right: 1rem;
	object-fit: contain;
}

.project-duration {
	font-weight: 550;
}

/* ATTACHMENT SECTION */

.UserAttachment-section {
	position: relative;
}

.attachment-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
	margin-bottom: 1rem;
}

.attachment-data {
	min-width: 10rem;
}

.attachment-content {
	display: flex;
}

.attachment-content span {
	color: var(--primary-color);
	font-size: 0.9rem;
	margin-left: 0.2rem;
	display: flex;
	align-items: center;
}

.attachment-content span:hover {
	text-decoration: underline;
}

.cross-delete-btn {
	position: absolute;
	right: 1%;
}

/* certificate */
.UserCertificate-section {
	position: relative;
}

.certificate-header {
	font-size: var(--heading-text-size);
	color: var(--heading-text-color);
	margin-bottom: 1rem;
}

.certificate-content {
	display: flex;
	align-items: center;
}

.certificate-content span {
	color: var(--primary-color);
	font-size: 0.9rem;
	margin-left: 0.2rem;
	display: flex;
	align-items: center;
}

.attachment-content span:hover {
	text-decoration: underline;
}

/* REACT CLASSES */

.textActive {
	display: inline;
}

.textDeactive {
	display: none;
}

.modalActive {
	display: block;
}

.modalDeactive {
	display: none;
}

#about textarea {
	width: 90%;
	margin-left: 1rem;
	margin-right: margin;
	outline: none;
	resize: none;
	height: 10vh;
}

.del-btn {
	font-size: 1.1rem;
	margin-left: 1rem;
	border: none;
	outline: none;
	position: absolute;
	right: 2%;
	top: 2rem;
	font-size: 2rem;
	border-radius: 50%;
}

.add-btn {
	/* position: absolute;
	top: 2rem;
	right: -15%; */
	outline: none;
	border: none;
}
.edit-exp {
	position: relative;
	border: 2px solid silver;
	padding: 5rem 1rem 1rem 1rem;
	margin: 1rem;
}

.skills-edit {
	position: relative;
}

.activejob {
	display: block;
}

.notactivejob {
	display: none;
}

.user-title-limit {
	display: block;
	width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* ================= MEDIA QUERY SECTION =========================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#user-profile {
		padding-inline: 10vw;
	}

	.user-leftContainer {
		width: 65vw;
	}

	.user-rightContainer {
		width: 13vw !important;
		right: 10vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#user-profile {
		padding-inline: 5vw;
	}

	.user-leftContainer {
		width: 75vw;
	}

	.user-rightContainer {
		width: 13vw !important;
		right: 5vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#user-profile {
		padding-inline: 1vw;
	}

	.user-leftContainer {
		width: 79vw;
	}
	.user-rightContainer {
		width: 17vw !important;
		right: 1vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#user-profile {
		padding-inline: 10vw;
		display: block;
	}
	.user-leftContainer {
		width: 80vw;
	}

	.user-rightContainer {
		/* height: 20rem; */
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#user-profile {
		padding-inline: 2vw;
		display: block;
	}
	.user-leftContainer {
		width: 96vw;
	}

	.user-rightContainer {
		height: 20rem;
		display: none;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	#user-profile {
		padding-inline: 0;
		display: block;
	}

	.flex-container {
		display: block;
	}

	.first-row,
	.second-row,
	.third-row {
		width: 90vw;
	}

	.user-leftContainer {
		width: 100vw;
	}

	.first-row-section > li,
	.second-row-section > li,
	.third-row-section > li {
		margin: 0;
		padding: 0;
		width: 96vw;
		padding-inline: 2vw;
	}

	.third-row li {
		position: relative;
	}

	.user-profile img {
		height: 6rem;
		width: 6rem;
	}

	.user-info .user-contact-details ul {
		display: block;
	}

	.user-contact-details {
		line-height: 1rem;
	}

	.user-info .userName {
		margin-bottom: 0;
		max-width: 170px;
	}

	.user-contact-details ul li i img {
		display: inline;
	}

	.profileimage-edit-btn {
		top: 50%;
	}

	.user-socialmedia {
		display: none;
	}

	.user-socialmedia {
		position: relative;
		width: 100%;
	}

	.userSocial-edit-btn {
		top: 8px;
		left: 6rem;
	}

	#social-media-mobile {
		display: flex;
	}

	.user-socialmedia li i {
		margin-right: 0.2rem;
	}

	.socialAdd-btn {
		top: 6%;
		right: 70%;
	}

	.investor-add-section {
		position: absolute;
		top: 0;
		right: 0;
	}

	.investor-redirect-btn {
		padding: 0.3rem 0.2rem !important;
		padding: 0;
		font-size: 0.9rem;
	}

	#education,
	#experience,
	#project,
	#attachments,
	#language,
	#certificate,
	.userAbout-section,
	.userSkills-section,
	.socialMedia-section {
		min-height: 7rem;
		max-height: fit-content;
	}

	.socialMedia-section {
		min-height: 5rem;
	}

	.small-screen-name,
	.medium-screen-name {
		display: none;
	}

	.user-leftContainer {
		padding-bottom: 1rem;
	}

	.singleUser-socialmedia {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.singleuser-socialMedia{
		margin-top: 0 !important;
	}

	.singleuser-socialMedia li {
		margin-bottom: 0 !important;
	}

	.singleUser-social-section{
		min-height:fit-content !important;
	}
}

@media (min-width: 0px) and (max-width: 450px) {
	.user-profile img {
		height: 4rem;
		width: 4rem;
		margin: 1rem;
	}

	.edit-user-button {
		top: 12%;
		left: 75%;
	}

	.profileimage-edit-btn {
		top: 4rem;
		right: 1.3rem;
	}

	.user-socialmedia {
		width: 100%;
	}

	.investor-redirect-btn {
		padding: 0.3rem 0.2rem !important;
		font-size: 0.7rem;
		color: var(--primary-color);
	}

	.user-contact-details li span {
		font-size: 0.75rem;
	}

	.medium-screen-name {
		display: block;
	}

	.big-screen-name {
		display: none;
	}
	/* .small-screen-name {
		display: block;
	}

	.medium-screen-name {
		display: none;
	} */
}

@media (min-width: 0px) and (max-width: 550px) {
	.long-title-bg {
		height: 18rem !important;
	}

	.user-social-name {
		display: none !important;
	}

	#social-media-mobile {
		justify-content: unset;
	}
	.user-profile-social-mobile {
		margin-right: 1rem;
	}
}

@media (min-width: 0px) and (max-width: 380px) {
	.edit-user-button {
		margin-top: 1.2rem;
	}

	.small-screen-name {
		display: block;
	}

	.medium-screen-name {
		display: none;
	}

	.user-info .userName {
		margin-bottom: 0;
		max-width: 130px;
		word-wrap: break-word;
	}

	.userProfession {
		width: 130px;
		word-wrap: break-word;
	}

	/* #bg-img,
	#user-info-section {
		height: 20rem;
	} */
	.long-title-bg {
		height: 22rem !important;
	}

	.user-email-limit {
		display: block;
		width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

@media (min-width: 0px) and (max-width: 300px) {
	.user-info .userName {
		margin-bottom: 0;
		max-width: 100px;
		word-wrap: break-word;
	}
}
