/* ============================= */
#three-section-startup-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-startup-container {
	display: flex;
	justify-content: space-between;
}

#three-section-startup-container .left-container {
	width: 12vw;
}
#three-section-startup-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-startup-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}
/* ============================= */

#startup-info-card {
	box-shadow: var(--profile-card-shadow);
	border-radius: 10px;
	margin-bottom: 32px;
	padding: 1rem;
	padding-bottom: 2rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	background-color: white;
	position: relative;
}

#startup-info-card hr {
	background-color: var(--primary-lightBlue-color);
	border: none;
	height: 1px;
}

#three-section-container {
	display: flex;
	margin-left: 15vw;
	margin-right: 15vw;
	margin-top: 5rem;
	/* background-color: white; */
}

#three-section-container .left-container {
	width: 12vw;
}
#three-section-container .mid-container {
	width: 40vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-container .right-container {
	width: 12vw;
	border: 1px solid purple;
}

#startup-details {
	display: flex;
	justify-content: space-between;
}

#startup-details li:last-child {
	width: 30%;
}

#startup-details .logo {
	border-radius: 50%;
	height: 3.5rem;
	width: 3.5rem;
	margin-right: 0.8rem;
	margin-bottom: 0.5rem;
	object-fit: contain;
}

/* #startup-details .company-detail li:first-child {
	width: 15%;
} */

#startup-details .company-detail {
	width: 70%;
}

#startup-details .company-detail li:last-child {
	width: 85%;
}
/* #startup-details .company-detail {
	display: flex;
} */

#startup-details .company-detail .company-location {
	font-weight: 500;
	color: var(--subHeading-text-color);
}

#startup-details .starup-socialMedia ul {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}

#startup-details .starup-socialMedia img {
	height: 25px;
	width: 25px;
}

.startupDetail-company-name {
	font-size: 20px;
	font-weight: 600;
}

.startupDetail-company-location p {
	font-weight: normal;
}

.startupDetail-company-description {
	font-size: 0.9rem;
	word-spacing: 0.1rem;
	color: var(--subHeading-text-color);
}

#startup-info-card .company-description {
	margin-top: 1rem;
	color: var(--subHeading-text-color);
	margin-bottom: 1rem;
	font-weight: 550;
	font-size: 0.9rem;
}

#startup-info-card .founder-flex-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1rem;
}

#startup-info-card .founder-flex-section li {
	width: 33%;
	margin-bottom: 1rem;
}

#startup-info-card .founder-flex-section li div {
	display: flex;
}

#startup-info-card .founder-flex-section li div p {
	color: var(--primary-color);
	font-weight: 550;
}

#startup-info-card .founder-flex-section li div h6 {
	color: var(--subHeading-text-color);
}

#startup-info-card .industry-section h4,
#startup-info-card .founder h4,
#startup-job h4 {
	color: var(--subHeading-text-color);
	margin-top: 0.7rem;
	font-size: 15px;
	margin-bottom: 0.5rem;
}

.first-row-table,
.second-row-table {
	width: 100%;
	/* text-align: left; */
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;
}

.first-row-table tr th,
.second-row-table tr th {
	width: 33.3%;
	color: var(--subHeading-text-color);
	font-size: 15px;
	text-align: center;
}

.first-row-table tr td,
.second-row-table tr td {
	/* position: relative;
	left: 20%;
	transform: translateX(-50%); */
	color: var(--primary-color);
	font-size: 13px;
	text-align: center;
}

.second-row-table tr td a {
	color: var(--primary-color);
}

/* .second-row-table tr .startup-status,
.second-row-table tr .startup-website {
	position: relative;
	left: 17%;
} */

/* #startup-info-card .upper-row ul,
#startup-info-card .upper-row ul span,
#startup-info-card .lower-row ul {
	display: flex;
	justify-content: space-between;
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;
} */

/* #startup-info-card .upper-row ul span h4,
#startup-info-card .lower-row ul h4 {
	text-align: center;
	color: var(--subHeading-text-color);
}

#startup-info-card .upper-row ul p,
#startup-info-card .lower-row ul p,
#startup-info-card .lower-row ul a,
#startup-info-card .industry-section li {
	text-align: center;
	color: var(--primary-color);
	font-weight: 550;
} */

#startup-info-card .industry-section ul {
	/* display: flex; */
	display: inline-block !important;
	flex-wrap: nowrap;
}

#startup-info-card .industry-section li {
	margin-right: 0.5rem;
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 0.5rem;
	cursor: default;
	display: inline-block;
}

#startup-info-card .industry-section span i {
	display: flex;
	justify-content: center;
	color: var(--primary-color);
}

.startup-about-job {
	word-wrap: break-word;
}
.startup-job-title {
	width: 85%;
}

.seemore-startup-job {
	height: 25vh;
	overflow: hidden;
}

.mobile-saved-section {
	display: none;
}

.startup-user-info {
	width: 70%;
}
/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-startup-container {
		padding-inline: 10vw;
	}

	#three-section-startup-container .left-container,
	#three-section-startup-container .right-container {
		width: 15vw;
	}

	#three-section-startup-container .mid-container {
		width: 46vw;
	}

	.seemore-startup-job {
		height: 25vh;
		overflow: hidden;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-startup-container {
		padding-inline: 5vw;
	}

	#three-section-startup-container .left-container,
	#three-section-startup-container .right-container {
		width: 17.5vw;
	}

	#three-section-startup-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-startup-container {
		padding-inline: 1vw;
	}

	#three-section-startup-container .left-container,
	#three-section-startup-container .right-container {
		width: 20vw;
	}

	#three-section-startup-container .mid-container {
		width: 54vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-startup-container {
		padding-inline: 10vw;
	}

	#three-section-startup-container .left-container {
		width: 25vw;
	}

	#three-section-startup-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-startup-container .right-container {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-startup-container {
		padding-inline: 2vw;
	}

	#three-section-startup-container .left-container {
		width: 24vw;
	}

	#three-section-startup-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-startup-container .right-container {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-startup-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
		margin-bottom: 5rem;
	}

	#three-section-startup-container {
		display: block;
	}

	#three-section-startup-container .right-container,
	#three-section-startup-container .left-container {
		display: none;
	}

	#three-section-startup-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}

	#startup-info-card {
		border-radius: 0;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.logo {
		height: 3.5rem;
		width: 3.5rem;
		margin-right: 0.7rem;
	}

	.mobile-limit {
		display: block;
		width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.startup-table {
		display: none;
	}

	.seemore-startup-job {
		height: 30vh;
		overflow: hidden;
	}

	.mobile-saved-section {
		display: block;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-startup-container {
		padding-inline: 0vw;
	}

	#three-section-startup-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-startup-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	#startup-details .logo {
		height: 3rem;
		width: 3rem;
		margin-right: 0.8rem;
	}

	#startup-details .starup-socialMedia img {
		height: 20px;
		width: 20px;
	}

	.startupDetail-company-name {
		font-size: 16px;
		font-weight: 700;
	}

	.mobile-founder-limit {
		display: block;
		width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.company-founder-info .founder-image {
		height: 3rem;
		width: 3rem;
	}

	.seemore-startup-job {
		height: 24vh;
		overflow: hidden;
	}
}

@media (min-width: 0px) and (max-width: 375px) {
	#startup-details .starup-socialMedia ul li {
		margin-right: 0.3rem;
	}

	#startup-details .starup-socialMedia img {
		height: 18px;
		width: 18px;
	}

	.first-row-table tr th,
	.second-row-table tr th {
		width: 33.3%;
		color: var(--subHeading-text-color);
		font-size: 12px;
	}

	#startup-details .company-detail {
		width: 100%;
	}

	.starup-socialMedia {
		position: absolute;
		bottom: 0.3rem;
		left: 4rem;
		width: 50%;
	}

	#startup-details {
		display: block;
		position: relative;
		padding-bottom: 1.5rem;
	}

	.startupDetail-company-description {
		font-size: 0.8rem;
	}

	.mobile-limit {
		width: 80px;
	}

	.company-founder-info .founder-image {
		height: 3rem;
		width: 3rem;
	}

	.founder-mobile {
		display: block !important;
	}

	.mobile-founder-limit {
		font-size: 0.9rem !important;
		width: 150px;
	}

	.founder-mobile li {
		width: 100% !important;
	}

	.startup-job-title {
		width: 75%;
	}

	.seemore-startup-job {
		height: 30vh;
		overflow: hidden;
	}
}

.review-container::-webkit-scrollbar{
	display:none
}

.modal-review::-webkit-scrollbar{
	display: none;
}