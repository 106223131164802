/* social media section */
.social-media {
	display: flex;
	justify-content: space-around;
	border-bottom: 2px solid silver;
	padding-bottom: 1rem;
	margin-top: 1rem;
}

.social-media li {
	display: flex;
}

.social-media li i {
	font-size: 1.5rem;
	margin-right: 0.3rem;
	display: flex;
	align-items: center;
}

.social-media li:hover {
	text-decoration: underline;
	text-decoration-color: #0a66c2;
	cursor: pointer;
}

.social-media li a {
	display: flex;
	align-items: center;
}

.social-media li span {
	font-size: 1rem;
	color: #0a66c2;
}

.twitter {
	color: #1da1f2;
}

.facebook {
	color: #1877f2;
}

.linkedin {
	color: #0a66c2;
	border-radius: 50%;
}

#button-value {
	background-color: #0a66c2;
	color: white;
	border-radius: 5px;
	padding: 0.5rem;
	font-weight: bold;
}

#button-value:hover {
	/* transition: all 0.3s ease; */
	text-decoration: underline;
	background-color: #2666cf;
}
