/* ============================= */
#three-section-investor-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
	margin-bottom: 5rem;
}

#three-section-investor-container {
	display: flex;
	justify-content: space-between;
}

#three-section-investor-container .left-container {
	width: 12vw;
}
#three-section-investor-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-investor-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

/* ============================================ */

.publicInvestor-info-card {
	box-shadow: var(--profile-card-shadow);
	border-radius: 10px;
	margin-bottom: 2rem;
	position: relative;
	background-color: white;
	padding: 40px 0 30px 0;
}

/* .overall-info, */
.publicInvestor-overall-section,
.publicInvestor-about-section,
.publicInvestor-investment-section,
.publicInvestor-mix-section,
.publicInvestor-market-section {
	padding: 0 30px;
	margin-bottom: 0.7rem;
}

/* OVERALL DETAIL PAGE------------------- */
.overall-info {
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	min-width: 80%;
}

.publiInvestor-connect-btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 2%;
	outline: none;
	border: none;
	background-color: var(--primary-color);
	color: white;
	font-weight: bold;
	border-radius: 5px;
	padding: 0.5rem 0.8rem;
}

.publicInvestor-overall-section {
	border-bottom: 1px solid silver;
	position: relative;
}

.overall-info > span {
	display: flex;
	/* align-items: center; */
	min-width: 70%;
	width: 80%;
}

.overall-info .image {
	height: 4rem;
	width: 4rem;
	border-radius: 5px;
	margin-right: 0.8rem;
}

.overall-info .location {
	color: var(--subHeading-text-color);
	font-weight: 550;
	font-size: 15px;
}

.details:hover {
	text-decoration: underline;
}

/* SOCIAL MEDIA */

#publicInvestor-socialMedia ul {
	display: flex;
}

#publicInvestor-socialMedia ul li {
	margin: 1px 10px 0px 0;
}

/* ---------------------------------- */

.publicInvestor-about-section .about,
.investement-heading,
.intrested-market-heading,
.publicInvestor-mix-section h5 {
	font-weight: 600;
	font-size: 16px;
	color: var(--subHeading-text-color);
	margin-bottom: 0.5rem;
}

.publicInvestor-info-card .about-description {
	line-height: 1.3rem;
	font-size: 15px;
	font-weight: 500;
	color: var(--subHeading-text-color);
	word-wrap: break-word;
}

/* About section */
.publicInvestor-about-section {
	border-bottom: 1px solid silver;
	padding-bottom: 1rem;
}

/* investment section  */

.publicInvestor-investment-section ul {
	display: flex;
}

.publicInvestor-investment-section .publicInvestor-investment {
	font-size: 15px;
}

/* Mix section */

.publicInvestor-mix-section {
	background-color: var(--primary-lightBlue-color);
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.publicInvestor-mix-section .investment-type {
	display: flex;
	/* justify-content: space-between; */
	justify-content: space-between;
}

.publicInvestor-mix-section span {
	/* display: block; */
	font-weight: 600;
	color: var(--subHeading-text-color);
}

.publicInvestor-mix-section div {
	text-align: center;
}

.publicInvestor-mix-section div .icons {
	color: var(--primary-color);
	height: 22px;
	width: 22px;
	margin-bottom: 0.2rem;
	margin-top: 0.2rem;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

/* Intrested Market section */

.publicInvestor-market-section .market-intrest ul {
	display: flex;
	flex-wrap: wrap;
}

.publicInvestor-market-section .market-intrest li {
	margin-right: 1rem;
}

.publicInvestor-market-section .market-intrest ul li i {
	display: flex;
	justify-content: center;
	font-size: 1.3rem;
	color: #0a66c2;
}
.publicInvestor-market-section .market-intrest ul li i img {
	height: 2rem;
	width: 2rem;
	margin-bottom: 0.3rem;
}

.publicInvestor-market-section .market-intrest ul span {
	display: block;
	color: var(--primary-color);
}

.publicInvestor-mix-section .about-description {
	word-wrap: break-word;
}

.investor-name-container {
	min-width: 70%;
	width: 70%;
}
.detailed-page-redirect-username {
	max-width: 65%;
	word-wrap: break-word;
}

.investor-name-container {
	width: 70%;
}

.investor-view {
	line-height: 0.8rem !important;
	margin-top: 0.2rem;
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-investor-container {
		padding-inline: 10vw;
	}

	#three-section-investor-container .left-container,
	#three-section-investor-container .right-container {
		width: 15vw;
	}

	#three-section-investor-container .mid-container {
		width: 46vw;
	}
}

@media (min-width: 0px) and (max-width: 1500px) {
	.overall-info .overall-name {
		font-size: 1.1rem;
		display: block;
		max-width: 250px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-investor-container {
		padding-inline: 5vw;
	}

	#three-section-investor-container .left-container,
	#three-section-investor-container .right-container {
		width: 17.5vw;
	}

	#three-section-investor-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-investor-container {
		padding-inline: 1vw;
	}

	#three-section-investor-container .left-container,
	#three-section-investor-container .right-container {
		width: 20vw;
	}

	#three-section-investor-container .mid-container {
		width: 54vw;
	}

	.publicInvestor-mix-section h5 {
		font-size: 0.9rem;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-investor-container {
		padding-inline: 10vw;
	}

	#three-section-investor-container .left-container {
		width: 25vw;
	}

	#three-section-investor-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-investor-container .right-container {
		display: none;
	}

	.publicInvestor-mix-section h5 {
		font-size: 0.9rem;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-investor-container {
		padding-inline: 2vw;
	}

	#three-section-investor-container .left-container {
		width: 24vw;
	}

	#three-section-investor-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-investor-container .right-container {
		display: none;
	}

	.publicInvestor-mix-section h5 {
		font-size: 0.9rem;
	}

	.overall-info .overall-name {
		width: 150px;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-investor-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
	}

	#three-section-investor-container {
		display: block;
	}

	#three-section-investor-container .right-container,
	#three-section-investor-container .left-container {
		display: none;
	}

	#three-section-investor-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.publicInvestor-info-card {
		border-radius: 0;
	}

	.publicInvestor-mix-section .investment-type {
		display: grid;
		grid-template-columns: 2fr 2fr;
		grid-row-gap: 0.5rem;
		padding: 1rem 0;
	}

	.publicInvestor-mix-section .investment-type div,
	.publicInvestor-mix-section .investment-type div span {
		text-align: left;
	}

	.publicInvestor-mix-section .investment-type div span {
		display: block;
		text-align: center;
		/* margin-left: 2rem; */
	}

	/* .publicInvestor-mix-section .investment-type div {
		width: 60%;
	} */

	/* .publicInvestor-overall-section,
	.publicInvestor-about-section,
	.publicInvestor-investment-section,
	.publicInvestor-mix-section,
	.publicInvestor-market-section {
		padding: 5px 10px 10px 10px;
		margin-bottom: 0.7rem;
	} */

	.publicInvestor-mix-section div .icons,
	.publicInvestor-mix-section .investment-type div span {
		position: relative;
		transform: translate(-50%);
		left: 30%;
	}

	.publicInvestor-overall-section,
	.publicInvestor-about-section,
	.publicInvestor-investment-section,
	.publicInvestor-market-section {
		padding: 5px 10px 10px 10px;
		margin-bottom: 0;
	}

	.publiInvestor-connect-btn {
		padding: 0.2rem 0.5rem;
	}

	.publicInvestor-info-card {
		margin-bottom: 1rem;
	}

	.publicInvestor-market-section .market-intrest ul {
		width: 100%;
	}

	.publicInvestor-market-section .market-intrest li {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		margin-bottom: 0.7rem;
	}

	.publicInvestor-info-card .about-description {
		font-size: 13px;
		margin-bottom: 0.7rem;
		/* line-heigh/t: 1.1rem; */
		word-wrap: break-word;
	}

	.publicInvestor-info-card {
		padding-top: 1rem;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-investor-container {
		padding-inline: 0vw;
	}

	#three-section-investor-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-investor-container .mid-container {
		margin-left: 0;
		width: 100%;
	}
	.overall-info .overall-name {
		font-size: 1.1rem;
		display: block;
		width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.investor-view-profile {
		position: absolute;
		right: 0.5rem;
		top: 10%;
		margin-bottom: 0.3rem;
	}

	.publicInvestor-market-section .market-intrest ul span {
		font-size: 12px;
	}
}

@media (min-width: 375px) and (max-width: 500px) {
	.investor-view-profile {
		position: absolute;
		top: 1.2rem;
		right: unset;
		margin-bottom: 0.3rem;
	}
}

@media (min-width: 0px) and (max-width: 375px) {
	.publicInvestor-mix-section h5,
	.publicInvestor-mix-section span {
		font-size: 0.8rem;
		font-weight: 550;
	}

	.overall-info .image {
		height: 3rem;
		width: 3rem;
		margin-right: 0.3rem;
	}

	.overall-info .overall-name {
		font-size: 0.9rem;
		width: 100px;
		display: block;
		width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		line-height: 1.5rem;
	}

	.overall-info .location {
		font-size: 6rem;
	}

	.publiInvestor-connect-btn {
		font-size: 0.8rem;
	}

	.investor-name-container {
		min-width: 75%;
		width: 75%;
	}

	.overall-info > span {
		width: 98%;
	}

	.detailed-page-redirect-username {
		max-width: 50%;
		word-wrap: break-word;
	}
}
