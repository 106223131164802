/* ============================= */
#three-section-joblist-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-joblist-container {
	display: flex;
	justify-content: space-between;
}

#three-section-joblist-container .left-container {
	width: 12vw;
}
#three-section-joblist-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-joblist-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}
#job-description .ql-editor {
	height: 15rem !important;
	background-color: #f4f6f8;
}
/* #job-description {
	height: 5rem !important;
}

.ql-editor {
	height: 5rem !important;
} */
.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}
/* ============================= */

.right-job-container h2 {
	text-align: center;
}

.job-post-section {
	box-shadow: var(--profile-card-shadow);
	/* background-color: var(--primary-lightBlue-color); */
	background-color: white;
	margin-bottom: 1.5rem;
	padding: 22px 27px 16px 33px;
	border-radius: 5px;
	position: relative;
}

.publicJob-common {
	margin-bottom: 18px;
}

.publicJob-about p > * {
	color: var(--subHeading-text-color) !important;
	background: white !important;
	word-wrap: break-word;
}

.publicJob-company-details {
	display: flex;
	justify-content: space-between;
}

.publicJob-company-details span:first-child {
	display: flex;
	align-items: center;
}

.job-company-image {
	height: 3.5rem;
	width: 3.5rem;
	margin-right: 1rem;
	border-radius: 5px;
}

.publicJob-company-details .job-title {
	font-size: 15px;
	font-weight: 700;
	color: black;
}

.publicJob-company-details .job-company-name {
	font-size: 15px;
	font-weight: 600;
	color: var(--subHeading-text-color);
}

.publicJob-mix-section ul {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0.9rem;
	margin-bottom: 0.9rem;
	margin-bottom: 0.5rem;
}

.publicJob-mix-section ul li {
	margin-right: 1rem;
	font-size: 15px;
	font-weight: 600;
}

.publicJob-about {
	font-size: 15px;
	color: var(--details-text-color);
	line-height: 1.5rem;
}

.publicJob-skills {
	display: flex;
	flex-wrap: wrap;
}

.publicJob-skills li {
	margin-right: 1rem;
	padding: 0.3rem 0.5rem;
	background-color: rgba(69, 138, 240, 0.1);
	margin-bottom: 0.7rem;
	border-radius: 5px;
	color: var(--primary-color);
	font-weight: 500;
	font-size: 12px;
	border: 1px solid var(--primary-color);
}

.publicJob-footer-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.publicJob-footer-section span {
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	color: var(--subHeading-text-color);
}

.job-apply-btn,
.jobDetail-apply-btn {
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 0.4rem 0.9rem;
	border-radius: 5px;
	color: white;
	background-color: var(--primary-color);
	font-weight: 500;
	/* position: absolute;
	right: 2%; */
}

/* .jobDetail-apply-btn {
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 0.4rem 1.5rem;
	border-radius: 5px;
	color: white;
	background-color: var(--primary-color);
	font-weight: bold;
} */

.job-applied-btn {
	background-color: var(--subHeading-text-color);
}

.job-applied-btn:hover {
	background-color: var(--subHeading-text-color);
}

.job-skills {
	font-size: 0.9rem;
	opacity: 0.7;
}

/* JOB DETAILED PAGE */

.detailed-job-title-section {
	/* padding: 18px 35px 1rem 35px; */
	/* background-color: white; */
	border: 10px;
	position: relative;
}

.detailed-job-recommend,
.detailedJob {
	border-radius: 5px;
	background-color: white;
	padding: 18px 35px 1rem 35px;
}

.detailedJob-company {
	display: flex;
	border-bottom: 1px solid silver;
	padding-bottom: 0.5rem;
}

.detailedJob-company li:last-child {
	margin-top: 0.7rem;
}

.detailedJob-company img {
	height: 68px;
	width: 68px;
	margin-right: 1rem;
}

.detailed-company-name {
	font-size: 25px;
	font-weight: 600;
	color: var(--heading-text-color);
}

.detailed-company-title {
	font-size: 12px;
	color: var(--subHeading-text-color);
}

.detailedJob-job-title {
	font-size: 16px;
	font-weight: 600;
}

.detailedJob-mix-section {
	margin-bottom: 0.5rem;
}

.detailedJob-description h4,
.detailedJob-company-details h4,
.detailedJob-skills h4 {
	font-weight: 600;
	margin-bottom: 0.5rem;
}

.detailedJob-description p,
.detailedJob-company-details p {
	font-size: 15px;
	color: var(--heading-text-color);
	word-wrap: break-word;
}

.detailedJob-description,
.detailedJob-company-details {
	margin-bottom: 0.7rem;
}

.detailedJob-title-section {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.detailedJob-title-section span {
	display: flex;
}

.detailedJob-title-section span p:first-child {
	margin-right: 1rem;
}
/* .detailedJob-title-section span p:last-child, */

.detailedJob-applicant {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: 550;
	color: var(--subHeading-text-color);
}

.detailedJob-mix-section {
	color: var(--subHeading-text-color);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.detailedJob-apply-btn {
	outline: none;
	border: none;
	/* width: 80%; */
	padding: 0.4rem 1.5rem;
	background-color: var(--primary-color);
	position: absolute;
	right: 2%;
	/* left: 50%; */
	/* transform: translate(-50%); */
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 600;
	color: white;
	transition: all 0.2s ease;
}

.detailedJob-apply-btn:hover {
	background-color: #50a0f0;
	transition: all 0.2s ease;
}

/* JOB APPLIED FORM */

.appliedJob-profile-section ul {
	display: flex;
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;
}

.appliedJob-profile-section ul img {
	height: 3rem;
	width: 3rem;
	margin-right: 0.7rem;
	border-radius: 50%;
}

.appliedJob-profile-section .applicant-location {
	font-size: 0.8rem;
}

.appliedJob-resume-section ul {
	display: flex;
	margin-top: 0.7rem;
}

.appliedJob-resume-section ul .not-select {
	margin-right: 1rem;
	font-size: 0.9rem;
	border: 1px solid silver;
	border-radius: 5px;
	padding: 0.5rem;
	cursor: pointer;
	font-weight: 550;
}

.appliedJob-resume-section ul .select {
	margin-right: 1rem;
	font-size: 0.9rem;
	border: 1px solid var(--primary-color);
	border-radius: 5px;
	padding: 0.5rem;
	font-weight: 550;
	color: var(--primary-color);
	cursor: pointer;
}

/* SAVED JOBS */

.saved-jobs-section {
	padding: 1.5rem 0 1rem 0;
	border: 1px solid silver;
	border-radius: 5px;
	min-height: 30rem;
	background-color: white;
}

.saved-jobs-section h2 {
	padding-left: 2rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid silver;
	color: var(--subHeading-text-color);
	font-size: 600;
}
.saved-jobs-section ul span {
	display: flex;
}

.saved-jobs-section ul {
	padding: 0 2rem;
	border-bottom: 1px solid silver;
}

.saved-jobs-section img {
	height: 4rem;
	width: 4rem;
	margin-right: 1rem;
	border-radius: 50%;
}

.saved-jobs-section .savedJob-span {
	display: block;
}

.saved-jobs-section .savedJob-title {
	font-weight: 600;
	font-size: 1.1rem;
}

.saved-jobs-section .savedJob-company,
.saved-jobs-section .savedJob-location {
	font-size: 0.8rem;
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-joblist-container {
		padding-inline: 10vw;
	}

	#three-section-joblist-container .left-container,
	#three-section-joblist-container .right-container {
		width: 15vw;
	}

	#three-section-joblist-container .mid-container {
		width: 46vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-joblist-container {
		padding-inline: 5vw;
	}

	#three-section-joblist-container .left-container,
	#three-section-joblist-container .right-container {
		width: 17.5vw;
	}

	#three-section-joblist-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-joblist-container {
		padding-inline: 1vw;
	}

	#three-section-joblist-container .left-container,
	#three-section-joblist-container .right-container {
		width: 20vw;
	}

	#three-section-joblist-container .mid-container {
		width: 54vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-joblist-container {
		padding-inline: 10vw;
	}

	#three-section-joblist-container .left-container {
		width: 25vw;
	}

	#three-section-joblist-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-joblist-container .right-container {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-joblist-container {
		padding-inline: 2vw;
	}

	#three-section-joblist-container .left-container {
		width: 24vw;
	}

	#three-section-joblist-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-joblist-container .right-container {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-joblist-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
		margin-bottom: 5rem;
	}

	#three-section-joblist-container {
		display: block;
	}

	#three-section-joblist-container .right-container,
	#three-section-joblist-container .left-container {
		display: none;
	}

	#three-section-joblist-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}

	#joblist-info-card {
		border-radius: 0;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.job-post-section {
		padding: 0.5rem 0.6rem;
		margin-bottom: 0rem;
		border-bottom: 1px solid lightgray;
	}

	.publicJob-footer-section p,
	.publicJob-footer-section span {
		font-size: 0.8rem;
	}

	#three-section-joblist-container .mid-container {
		height: 82vh;
		padding-top: 2rem;
	}

	.hide-job-mobile {
		display: none !important;
	}

	.detailed-job-recommend,
	.detailedJob {
		padding: 0.5rem;
		/* padding: 18px 35px 1rem 35px; */
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-joblist-container {
		padding-inline: 0vw;
	}

	#three-section-joblist-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-joblist-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	.publicJob-company-details img {
		height: 3rem;
		width: 3rem;
	}

	.publicJob-company-details .job-company-name,
	.publicJob-company-details .job-title {
		font-size: 0.9rem;
	}

	.job-title-mobile-limit {
		display: block;
		width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.publicJob-mix-section ul {
		/* justify-content: space-between; */
	}

	.publicJob-mix-section ul li span {
		line-height: 0.9rem;
		font-size: 0.85rem;
		display: inline-block;
	}

	.job-apply-btn,
	.jobDetail-apply-btn {
		padding: 0.3rem 0.6rem;
		font-size: 0.9rem;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	.publicJob-mix-section ul li span {
		font-size: 0.8rem;
	}

	.publicJob-company-details span {
		line-height: 1rem;
	}

	.publicJob-about .about-job {
		font-size: 0.85rem;
	}
}
