#chat-parent-container {
	display: flex;
	margin-top: 5rem;
	padding-inline: 15vw;
	height: calc(100vh - 5rem);
}

.mini-chat-loading {
	min-height: 50vh;
}

#investment-date {
	display: none !important;
}
/* .connection-common-btn {
	display: none !important;
} */

/* ========= for no recent user============ */
#three-section-message-container {
	display: flex;
}
#three-section-message-container .left-container {
	width: 12vw;
}
#three-section-message-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-message-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

/* =========================================== */

/* Chat leftContainer */
.chat-leftContainer {
	width: 50vw;
	border-top-left-radius: 10px;
	background-color: white;
}

.no-message-leftcontainer {
	/* background-color: #f4f5f7 !important; */
	padding-right: 8vw !important;
}

.chat-parent-nomessage {
	margin-top: 5rem !important;
}

.message_person-section {
	overflow-y: auto;
	overflow-x: hidden;
}

/* chat rightContainer */

.chat-rightContainer {
	width: 20vw;
	/* background-color: white; */
	border-top-right-radius: 10px;
	/* height: 90vh; */
}

.chat-rightContainer img {
	height: 5rem;
	width: 5rem;
	border-radius: 50%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-top: 1rem;
}

.chat-rightContainer .main-heading {
	text-align: center;
}

.chat-rightContainer .detail-heading {
	margin: 0.7rem 0;
	margin-left: 1rem;
	color: var(--subHeading-text-color);
}

.chat-rightContainer .skills {
	display: flex;
	flex-wrap: wrap;
}

.chat-rightContainer .skills li {
	background-color: var(--profile-card-color);
	padding: 0.5rem;
	border-radius: 5px;
	margin: 0.5rem 0.7rem;
	font-size: 0.8rem;
	font-weight: 500;
	box-shadow: var(--profile-card-shadow);
}

.chat-rightContainer .attachment {
	margin: 0.5rem;
	font-size: 0.9rem;
	display: flex;
	flex-wrap: wrap;
}

.chat-rightContainer .attachment a {
	display: flex;
	align-items: center;
	color: var(--primary-color);
	font-weight: 550;
}

.chat-rightContainer .attachment-name {
	padding: 0.7rem;
	background-color: var(--profile-card-color);
	margin: 0 0.3rem;
	border-radius: 5px;
}

.chat-rightContainer .user-message-social-media {
	display: flex;
	justify-content: space-between;
	width: 50%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.user-message-social-media img {
	height: 1.7rem;
	width: 1.7rem;
}

.exp {
	padding: 0.7rem 0.8rem;
	display: flex;
	margin-bottom: 1.5rem;
	background-color: var(--profile-card-color);
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
}

/* .chat-rightContainer  */
/* ======================================== */

.message-container {
	height: auto;
}

.message-container > ul {
	display: flex;
}

.message-left-container {
	width: 20vw;
	border-top-left-radius: 10px;
	background-color: white;
	/* border: 1px solid lightgray;
	border-right: none;
	background: var(--primary-light-color); */
}

/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
	border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: slategrey;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--primary-color);
}

.message-persons img {
	/* height: 2.5rem;
	width: 2.5rem; */
	border-radius: 50%;
	margin-right: 0.7rem;
}

.recent-user-info {
	display: flex;
}

.recent-user-info p:first-child {
	font-weight: bold;
}

.recent-user-info p:last-child {
	font-weight: 550;
}

.message-persons li:nth-child(2) {
	opacity: 0.8;
}

.message-persons p {
	font-size: 0.8rem;
	opacity: 0.7;
	height: 1.3rem;
}

.message-persons h5 {
	margin-top: 0.5rem;
	font-weight: lighter;
}

/* RIGHT CONTAINER */

.message-right-container {
	width: 30vw;
	position: relative;
	border: 1px solid var(--primary-light-color);
	border-top: none;
	border-bottom: none;
	height: calc(100vh - 5rem);
}

.message-userInfoContainer {
	/* width: ; */
	width: 50vw;
	position: relative;
	background-color: white;
	height: calc(100vh - 5rem) !important;
}

/* RIGHT CONTAINER HEADING  */

/* RIGHT CONTAINER BODY */

#chat-body {
	overflow-y: auto;
	/* height: 75vh; */
	scroll-padding-top: 2rem;
	background: white;
	overflow-x: hidden;
	/* height: auto !important; */
	height: calc(100vh - 13.5rem) !important;
	padding-bottom: 6rem;
	-ms-overflow-style: none; 
	scrollbar-width: none; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
#chat-body::-webkit-scrollbar {
	display: none;
}

.body > ul {
	margin: 0rem 1rem;
	position: relative;
}

.delete-option-modal {
	z-index: 100;
}

/* -----Media------------- */

.userside-message .message-image {
	height: 8rem;
	/* max-width: 15rem; */
}

.receiverside-message .message-image {
	height: 8rem;
	/* width: 15rem; */
}

.file-flex {
	display: flex;
}

.user-media-title {
	/* color: white; */
	color: black !important;
}

.receiver-media-title {
	color: black;
}

.message-pdf {
	height: 2rem;
	width: 2rem;
}

.file-flex p {
	display: flex;
	align-items: center;
}

/* ---------------------- */

.usermessage-image {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	display: flex;
	align-self: flex-end;
}

.usermessage-image {
	margin-left: 0.5rem;
}

.receiver-left-image {
	margin-right: 0.5rem;
}

.user-messages .none {
	display: none;
}

.user-side {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
}

.receiver-side {
	display: flex;
	margin-left: 0.5rem;
}

.receiverside-message-options {
	display: none;
}

.userside-message-options {
	display: block;
}

.user-side:hover .userside-message-options {
	display: flex;
}

.user-messages .receiverside-message {
	background-color: whitesmoke;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 0px;
	padding: 0.5rem;
	color: black;
	max-width: 80%;
	word-wrap: break-word;
	word-spacing: 2.5px;
	line-height: 1.3rem;
	font-weight: normal;
	word-break: break-word;
}

.user-messages .userside-message {
	/* background-color: var(--primary-color); */
	background-color: #efeded;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	/* color: white; */
	color: black !important;
	padding: 0.5rem;
	max-width: 80%;
	word-wrap: break-word;
	word-break: break-all;
	word-spacing: 2.5px;
	line-height: 1.3rem;
	font-weight: normal;
	word-break: break-word;
}

.user-messages .userside-message .read-more-btn {
	/* color: whitesmoke !important; */
	/* color: black !important; */
	color: var(--subHeading-text-color) !important;
}
.user-messages .receiverside-message .read-more-btn {
	color: var(--subHeading-text-color) !important;
}

.body p,
#chat-body p {
	font-size: 0.83rem;
}

/* FOOTER SECTION */

/* .message-footer {
	position: relative;
	min-height: 3rem;
	max-height: 10rem;
} */

.message-footer div {
	display: flex;
	background-color: white;

}

/* .message-footer input {
	border: 1px solid silver;
	outline: none;
	padding: 0.7rem;
	width: 100%;
	border-radius: 5px;
} */

/* .message-footer textarea {
	border: 1px solid silver;
	outline: none;
	resize: none;
	width: 100%;
	border-radius: 5px;
	height: 2.5rem;
	font-size: 0.9rem;
	padding: 0.5rem;
} */
.file-add {
	display: flex;
	align-items: flex-end;
	margin-left: 0.5rem;
}
.add-file {
	width: 2rem !important;
}
.send-message-btn {
	outline: none;
	border: none;
	background-color: var(--primary-color);
	color: white;
	font-weight: 550;
	padding: 0.6rem 0.6rem 0.5rem 0.7rem;
	margin: 0.2rem 0.5rem 0 0.5rem;
	font-size: 1rem;
	border-radius: 5px;
}

/* ---------Chat preview--------- */

.chat-file-upload input {
	display: inline;
}

.chat-image-upload input {
	width: 70%;
	padding: 0.4rem 0;
	border-radius: 5px;
}

.chat-image-preview span {
	width: 100%;
	display: block;
}

.chat-image-preview input {
	width: 70%;
	padding: 0.5rem 0;
	margin-left: 0.5rem;
	margin-bottom: 1rem;
}

.chat-image-preview img {
	max-height: 20rem;
	max-width: 20rem;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.no-message-container {
	height: 70vh;
}

.message-load-container {
	height: calc(100vh - 5rem) !important;
}

.messge-limiting {
	width: 50%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.message-parser {
	display: block;
	width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* Hide scrollbar for IE, Edge and Firefox */
.message_person-section {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	height: calc(100vh - 12rem);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.message_person-section::-webkit-scrollbar {
	display: none;
}

/* ==============For Responsive Design============================== */

@media screen and (min-width: 1201px) and (max-width: 1300px) {
	#chat-parent-container {
		padding-inline: 10vw;
		margin-top: 4rem;
	}

	.chat-leftContainer {
		width: 55vw;
	}

	.message-right-container {
		width: 33vw;
	}
	.message-left-container {
		width: 28vw;
	}

	.chat-rightContainer {
		width: 25vw;
	}

	.userside-message .message-image {
		height: 7rem;
	}

	.message-userInfoContainer {
		width: 52vw;
		position: relative;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
	#chat-parent-container {
		padding-inline: 5vw;
		margin-top: 3.5rem;
	}

	.chat-leftContainer {
		width: 60vw;
	}

	.chat-rightContainer {
		width: 30vw;
	}

	.message-right-container {
		width: 35vw;
	}

	.message-left-container {
		width: 25vw;
	}

	.userside-message .message-image {
		height: 6rem;
	}

	.message-userInfoContainer {
		width: 65vw;
		position: relative;
	}
}

@media screen and (min-width: 950px) and (max-width: 1023px) {
	#chat-parent-container {
		padding-inline: 1vw;
		margin-top: 4rem;
	}

	.chat-leftContainer {
		width: 64vw;
	}

	.chat-rightContainer {
		width: 34vw;
	}

	.message-right-container {
		width: 38vw;
	}

	.message-left-container {
		width: 26vw;
	}

	.userside-message .message-image {
		height: 6rem;
	}

	.message-userInfoContainer {
		width: 72vw;
		position: relative;
	}
}

@media screen and (max-width: 949px) {
	.chat-rightContainer {
		display: none;
	}
}

@media screen and (min-width: 850px) and (max-width: 949px) {
	#chat-parent-container {
		padding-inline: 10vw;
		margin-top: 4rem;
	}

	.chat-leftContainer {
		width: 100%;
	}

	.message-left-container {
		width: 30vw;
	}
	.message-right-container {
		width: 50vw;
	}

	.message-userInfoContainer {
		width: 50vw;
		position: relative;
	}
}

@media screen and (min-width: 641px) and (max-width: 849px) {
	#chat-parent-container {
		padding-inline: 0vw;
		margin-top: 4rem;
	}

	.chat-leftContainer {
		width: 100%;
	}

	.message-left-container {
		width: 40vw;
	}
	.message-right-container {
		width: 60vw;
	}
	.chat-rightContainer {
		display: none;
	}

	.message-userInfoContainer {
		width: 60vw;
	}

	.chat-user-experience-tablet {
		width: 80%;
	}
	.investor-connection-btn {
		/* display: none !important; */
		padding: 0.5rem !important;
		font-size: 0.8rem;
	}
}

@media screen and (orientation: landscape) {
	.mobile-chat-main-container #chat-body {
		height: calc(100vh - 3vh) !important;
	}
}

@media screen and (min-width: 0px) and (max-width: 640px) {
	#chat-parent-container {
		padding-inline: 1vw;
		width: 98vw;
		margin-top: 3rem;
	}

	.message-right-container {
		display: none;
	}

	.chat-rightContainer,
	.message-userInfoContainer {
		display: none;
	}

	.chat-leftContainer {
		width: 98vw;
		height: 90vh;
	}

	.message-left-container {
		width: 98vw;
		height: 90vh;
	}

	.user-messages .receiverside-message,
	.user-messages .userside-message {
		max-width: 80%;
	}

	.chat-user-experience-tablet {
		width: 100%;
	}
	#chat-body {
		overflow-y: auto;
		height: 85vh !important;
		scroll-padding-top: 2rem;
		background: #f6fbf4;
		overflow-x: hidden;
	}

	.mobile-chat-main-container {
		height: calc(100vh - 5rem);
	}

	.mobile-message-loading {
		min-height: 4rem;
	}

	.message_person-section {
		height: calc(100vh - 14.5rem);
	}

	/* .mobile-chat-footer {
		margin-bottom: 0.45rem;
	} */
	/* ==============connection btn============== */

	.user-connection-btn {
		/* display: none !important; */
		padding: 0.3rem !important;
		font-size: 0.9rem;
	}

	.investor-connection-btn {
		/* display: none !important; */
		padding: 0.25rem 0.3rem !important;
		font-size: 0.8rem;
	}
	/* ================================================= */
}

/* ==========================mini chat============= */

/* .mini-chat-parent {
	position: fixed;
	bottom: 3%;
	display: flex;
	width: 25%;
	z-index: 10;
	right: 300px;
} */

.mini-chat-parent-1{
	right: 0%;
}

.mini-chat-parent-2{
	right: 33%;
}

.mini-chat-parent-3{
	right: 66%;
}


.common-mini-chat {
	background-color: white;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	margin-right: 1rem;
	width: 20vw;
	z-index: 10;
	min-height: 50vh;
}

.mini-chat-1 {
	position: absolute;
	bottom: 0;
	right: 0%;
}

.mini-chat-2 {
	position: absolute;
	bottom: 0;
	right: 33%;
}

.mini-chat-3 {
	position: absolute;
	bottom: 0;
	right: 66%;
}

.mini-chat-body {
	height: 45vh;
	background-color: white;
	overflow-y: auto;
	overflow-x: hidden;
	font-size: 0.8rem;
}

.mini-chat-heading ul {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	background-color: white;
}

.mini-chat-heading ul span {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.mini-name-limit {
	display: block;
	width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.mini-chat-heading .mini-image {
	height: 2.5rem !important;
	width: 2.5rem !important;
	border-radius: 100%;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mini-chat-heading .mini-close {
	font-weight: bolder;
}

.mini-parent {
	display: flex;
	justify-content: space-around;
}

/* ======mini chat body================= */

.mini-chat-user-messages li {
	display: flex;
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;
}

/* ==================Media======================= */

.userside-message-mini,
.receiverside-message-mini {
	max-width: 75% !important;
}

.mini-chat-user-messages .mini-message-image {
	object-fit: contain;
	height: 6rem;
}

.mini-chat-user-messages .file-flex {
	display: flex;
}

.mini-chat-user-messages .user-media-title {
	color: white;
}

.mini-chat-user-messages .receiver-media-title {
	color: black;
}

.mini-chat-user-messages .message-pdf {
	height: 2rem;
	width: 2rem;
}

.mini-chat-user-messages .file-flex p {
	display: flex;
	align-items: center;
}

/* ---------------------- */

.mini-chat-user-messages .usermessage-image {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
}

.mini-chat-user-messages .usermessage-image {
	margin-left: 0.5rem;
}

.mini-chat-user-messages .receiver-left-image {
	margin-right: 0.5rem;
}

.mini-chat-user-messages .none {
	display: none;
}

.mini-chat-user-messages .user-side {
	justify-content: flex-end;
	margin-right: 0.3rem;
}

.mini-chat-user-messages .user-side-time {
	justify-content: flex-end;
	font-size: 10px;
	margin-right: 2.3rem;
}

.mini-chat-user-messages .receiver-side-time {
	font-size: 10px;
	margin-left: 3rem;
}

.mini-chat-user-messages .receiverside-message {
	background-color: whitesmoke;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 0px;
	padding: 0.5rem;
	color: var(--primary-color);
	color: black;
	font-weight: 600;
	max-width: 60%;
}

.mini-chat-user-messages .userside-message {
	/* background-color: var(--primary-color); */
	background-color: #efeded;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	/* color: white; */
	color: black !important;
	padding: 0.5rem;
	line-break: anywhere;
	max-width: 60%;
}

.mobile-send-message-btn {
	padding: 0.2rem !important;
	margin: 0.2rem;
	border-radius: 2px;
}

.mini-chat-footer {
	padding-bottom: 0.2rem;
	border-top: 1.5px solid silver;
	position: fixed;
	bottom:0;
	width:inherit

}

.mini-chat-footer input {
	/* border: 1px solid silver; */
	outline: none;
	padding: 0.3rem;
	border: none;
	/* width: 80%; */
	/* border-radius: 5px; */
}

.mini-chat-footer textarea {
	resize: none;
	width: 100%;
	outline: none;
	border: none;
	padding-right: 2rem;
	font-size: 0.9rem;
}

.mini-chat-footer .resize-textarea {
	height: 10rem;
}

/* .mini-chat-user-messages .receiver-side-time,
.mini-chat-user-messages .user-side-time {
	font-size: 1rem;
} */

@media (min-width: 1023px) and (max-width: 1200px) {
	.common-mini-chat {
		width: 30vw;
		min-height: 50vh;
	}

	.mini-chat-heading {
		min-height: 3rem;
	}
}

@media (min-width: 950px) and (max-width: 1023px) {
	.common-mini-chat {
		width: 30vw;
	}
}

@media (min-width: 850px) and (max-width: 949px) {
	.common-mini-chat {
		width: 35vw;
	}
}

@media (min-width: 641px) and (max-width: 849px) {
	.common-mini-chat {
		width: 45vw;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	/* ==============connection btn============== */

	.investor-connection-btn {
		/* display: none !important; */
		padding: 0.2rem !important;
		font-size: 0.8rem;
	}
	.investor-connection-btn-mobile {
		position: absolute;
		bottom: 1rem;
		right: 1%;
	}
	/* ================================================= */
}



/* for the new post send feature */
.card{
	padding: 0.5rem !important;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.card-content img{
	max-width: 100%;
	max-height: 400px;
	border-radius: 5px;
}

.card-content h3{
	font-weight:bold;
	font-size: 1rem;
}

.card-content a{
	text-decoration: underline;	
	word-wrap: break-word;
	width: 100%;
}

.card-info{
	display: flex;
	flex-direction: column;
	gap: 2rem;
	max-width: 100%;
}

.card-post-title{
	display: flex;
	gap: 0.5rem;
	align-items: center;
}
.card-post-title img{
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
}

.card-info__company{
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.card-info__company img{
	width: 1.5rem;
	height: 1.5rem;
}

.mini-chat-user-messages a{
	width: 100%;
}

.card-content *{
	/* color: white; */
	color: black !important;
}

.receiverside-message .card-content *{
	color: black;
}

.mini-chat-user-messages .card-content{
	color: black;
}

.mini-chat-user-messages .card-info img{
	max-width: 100%;
	margin: auto;
}

.mini-chat-user-messages .receiverside-message a{
	width: 100%;
	word-wrap: break-word;
}

.mini-chat-user-messages .userside-message a{
	width: 100%;
	word-wrap: break-word;
}


/* for the investor card  in message */

.investor-card{
	padding: 8px 8px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.investor-card__profileDetails{
	display: flex;
	gap: 10px;
}

.investor-card__profileDetails img{
	min-width: 50px;
	min-height: 50px;
	max-width: 60px;
	max-height: 60px;
	border-radius: 10px;
}

.investor-card__profileDetails .profileDetails__info{
	display: flex;
	flex-direction: column;
	gap: 5px;
	flex: 1;
}

.investor-card__profileDetails .profileDetails__info h3{
	font-weight: bold;
	width: 100%;	
	font-size: 1.05rem;
}

.investor-card__profileDetails .profileDetails__info p{
	width: 100%;
}

.investor-card__about h3{
	font-size: 1.1rem;
	font-weight: bold;
}

.investor-card__about p{
	margin-top: 1rem;
	display: flex;
}

.investor-card__investments{
	width: 100%;
}

.investor-card__investments h3{
	font-size: 1.02rem;
	font-weight: bold;
}

.investor-card__investments ul{
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.investor-card__investments ul li{
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
}

.investor-card__investments ul li h4{
	font-size: 0.9rem;
	font-weight: 500;
}

.investor-card__investments ul li svg{
	width: 20px;
	height: 20px;
}

.mini-chat-user-messages .investor-card{
	padding: 8px;
}

.mini-chat-user-messages ul{
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.mini-chat-user-messages .investor-card__profileDetails .profileDetails__info{
	align-items: center;
}

.receiverside-message .investor-card{
	color: black;
}

/* for startup messages  */

.startup-card{
	padding: 8px 8px;
	display: flex;
	flex-direction: column;
}

.startup-card .startup-card-head{
	display: flex;
	gap: 1rem;
	align-items: center;
}

.startup-card .startup-card-head img{
	min-width: 30px;
	min-height: 30px;
	max-width: 40px;
	max-height: 40px;
	border-radius: 100%;
}

.startup-card .startup-card-head .startup-company-info{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.startup-card .startup-card-head h2{
	font-size: 1.1rem;
	font-weight: 500;
}

.startup-card .startup-card-about{
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.startup-card .startup-card-about h4{
	font-size: 16px;
	font-weight: bold;
}

.startup-card .startup-card-details{
	border-top: #f6fbf4 1px solid;
	border-bottom: #f6fbf4 1px solid;
}

.startup-card .startup-card-details ul{
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: center;
}

.startup-card .startup-card-details ul li{
	min-width: 20%;
	max-width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}

.startup-card .startup-card-details ul li h3{
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}

.mini-chat-user-messages .startup-card{
	width: auto;
	min-width: auto;
}

.mini-chat-user-messages .startup-card .startup-card-details ul{
	flex-direction: column;
	align-items: center;
}
.receiverside-message .startup-card{
	color: black;
 }


/* for the job card */

.job-card{
	padding: 8px 8px;
	display: flex;
	flex-direction: column;
	min-width: 400px;
}

.mini-chat-user-messages .job-card{
	width: auto;
	min-width: auto;
}

.job-card .job-card-head{
	display: flex;
	gap: 1rem;
	align-items: center;
}

.job-card .job-card-head img{
	min-width: 40px;
	min-height: 40px;
	max-width: 60px;
	max-height: 60px;
	border-radius: 100%;
}

.job-card .job-card-head .job-card__company-info{
	display: flex;
	flex-direction: column;
}

.job-card .job-card-head .job-card__company-info h2{
	font-size: 1rem;
	font-weight: 600;
}

.job-card .job-card-head .job-card__company-info h3{
	margin-top: 5px;
}

.job-card ul{
	margin-top: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: space-evenly;
}

.job-card ul li{
	display: flex;
	gap: 5px;
	align-items: center;
}

.job-card .job-card__company-about{
	margin-top: 1.5rem;
	/* color: white !important; */
	color: black !important;

}

.job-card .job-card__company-about h3{
	font-weight: 500;
	font-size: 14px;
}

.job-card .job-card__company-about *{
	/* color: white !important; */
	color: black !important;
}

.receiverside-message .job-card .job-card__company-about{
	color: black !important;
}

 /* for og message Card */

.og-card {
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 8px;
    background-color: white;
    box-shadow: 0 0 0 10px solid rgba(0, 0, 0, 0.3);
    padding: 8px 1rem;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    width: calc(100% + 1rem);
    position: relative;
    right: 0.5rem;
    bottom: 0.5rem;
    border: 1px solid #dbdbdb;
    border-top-left-radius: 10px;
}

.og-card:hover{
	box-shadow: 0 0 0 0.3px rgba(0, 0, 0, 0.3);
}

a.og-card h3.og_heading{
	font-weight: bold;
	color: #1991eb;
	font-size: 0.875rem !important;
	word-spacing: 0% !important;
	overflow: hidden;
}

.og-card__meta{
	display: flex;
	gap: 12px;
	margin-bottom: 1rem;
	/* align-items: center; */
	overflow: hidden;
}

.og-card__meta img{
	height: 100%;
	min-width: 3rem;
	max-width: 3rem;
}

.og-card__meta p{
	line-height: 1.1;
	font-size: medium;

}

.og-card__meta-img{
	/* width: 4rem; */
	width: auto;
	/* height: 100%; */
}

@media  screen and (max-width: 768px) {
	.og-card__meta{
		gap: 12px;
		flex-direction: column;
		margin-bottom: 1rem;
		/* align-items: center; */
		overflow: hidden;
	}

	.og-card__meta-img{
		width: 100%;
		/* height: 100%; */
	}

	.og-card__meta img{
		/* width: 100%; */
		max-width: 100%;
		max-height: 100%;
		/* margin: auto; */
	}
	
}



/* og card for mini chat  */

.mini-chat-user-messages .userside-message .og-card{
	width: calc(100% + 1rem);
	gap: 12px;
}

.mini-chat-user-messages .userside-message .og-card__meta{
	display: flex;
	gap: 12px;
	flex-direction: column;
	margin-bottom: 1rem;
	/* align-items: center; */
	overflow: hidden;
}



/* for some ui correction */


.mini-chat-footer.message-footer div{
	/* position: relative; */
}


/*========== media queries for miniChatBox==================== */

@media screen and (min-width:2350px) and (max-width:2560px){
	.mini-chat-body{
		height:58vh !important;

	}	
}

@media screen and (min-width:2000px) and (max-width:2350px){
	.mini-chat-body{
		height:52vh !important;

	}	
}

@media screen and (min-width:1440px) and (max-width:2000px){
	.mini-chat-body{
		height:48vh !important;

	}
}

@media screen and (min-width:1024px) and (max-width:1440px){
	.mini-chat-body{
		height:42vh !important;

	}	
}

.mini-chat-body::-webkit-scrollbar {
	display:none;
	
  }