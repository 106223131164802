.submit-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.continue-btn {
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-top: 1rem;
	font-size: 1rem;
	font-weight: bold;
	padding: 1rem;
	background-color: black;
	color: white;
	outline: none;
	border: 1px solid black;
}

.continue-btn:hover {
	background-color: white;
	color: black;
	border-radius: 5px;
	transition: all 0.3s ease;
}
