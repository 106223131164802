.aboutus-container {
	width: 60vw;
	margin-inline: 20vw;
	height: fit-content;
	padding-bottom: 3rem;
	min-height: calc(100vh - 5rem);
}

.aboutus-container h2 {
	font-size: 1.5rem;
	padding-inline: 3rem;
	font-weight: 600;
	padding-top: 1rem;
}

.aboutus-container p {
	font-size: 0.9rem;
	color: var(--subHeading-text-color);
	padding-inline: 3rem;
	padding-top: 1rem;
}

@media (min-width: 1201px) and (max-width: 1300px) {
}
@media (min-width: 1024px) and (max-width: 1200px) {
	.aboutus-container {
		width: 65vw;
		margin-inline: 17.5vw;
		height: fit-content;
		padding-bottom: 3rem;
	}
}

@media (min-width: 950px) and (max-width: 1023px) {
	.aboutus-container {
		width: 70vw;
		margin-inline: 15vw;
		height: fit-content;
		padding-bottom: 3rem;
	}

	.aboutus-container h2 {
		padding-inline: 2rem;
	}

	.aboutus-container p {
		padding-inline: 2rem;
		padding-top: 0.8rem;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	.aboutus-container {
		width: 75vw;
		margin-inline: 12.5vw;
		height: fit-content;
		padding-bottom: 3rem;
	}

	.aboutus-container h2 {
		padding-inline: 1.75rem;
	}

	.aboutus-container p {
		padding-inline: 1.75rem;
		padding-top: 0.7rem;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	.aboutus-container {
		width: 80vw;
		margin-inline: 10vw;
		height: fit-content;
		padding-bottom: 3rem;
	}

	.aboutus-container h2 {
		padding-inline: 1.5rem;
	}

	.aboutus-container p {
		padding-inline: 1.5rem;
		padding-top: 0.7rem;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	.aboutus-container {
		width: 96vw;
		margin-inline: 2vw;
		height: fit-content;
		padding-bottom: 7rem;
		margin-top: 4rem !important;
	}

	.aboutus-container h2 {
		padding-inline: 1rem;
		line-height: 1.6rem;
		font-size: 1.2rem;
	}

	.aboutus-container p {
		padding-inline: 1rem;
		padding-top: 0.7rem;
	}
}

@media (min-width: 0px) and (max-width: 380px) {
	.aboutus-container h2 {
		padding-inline: 0.5rem;
	}

	.aboutus-container p {
		padding-inline: 0.5rem;
		padding-top: 0.7rem;
	}
}
