.email-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 2rem 1rem;
	border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 640px) {
	.email-container {
		width: 90vw;
	}
	.email-container h1 {
		display: block;
		text-align: center;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	.email-container {
		width: 100vw;
	}
}
