#mobile-navbarIcons {
	display: none;
}

.mobile-view,
.header-company-logo-mobile {
	display: none;
}

#navbar {
	box-shadow: 0 0.01px 5px silver;
	background: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 45;
}

#navbar > ul {
	display: flex;
	justify-content: space-between;
	padding-left: 15vw;
	padding-right: 15vw;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	align-items: center;
}

#tablet-navbarIcons {
	display: none;
}

.content .header-logo {
	width: 20%;
}

.content .header-search {
	width: 35%;
}

.content .header-icons {
	width: 50%;
}

.header-company-logo {
	height: 2.5rem;
}

.header-user-img {
	height: 1.3rem;
	width: 1.3rem;
	border-radius: 50%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.navbarIcons {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.header-search input {
	width: 100%;
	padding: 0.55rem;
	border: none;
	outline: none;
	background-color: #eef3f8;
	font-size: 1rem;
	border-radius: 5px;
}

.navbarIcons li i img {
	display: flex;
	color: #0a66c2;
	height: 1.3rem;
	width: 1.3rem;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.navbarIcons li p {
	color: black;
	/* font-size: 0.9rem; */
	font-size: 12px;
}

.active {
	font-weight: bolder !important;
}

.profile-icon .dropdown-icon {
	display: flex;
	cursor: pointer;
	align-items: center;
}

/* ======================DROP DOWN MENU====================== */

.drop-down-menu {
	position: absolute;
	margin-left: -12rem;
	margin-top: 0.7rem;
	top: 3rem;
}

.menu-list {
	background-color: white;
}

.menu-items {
	color: var(--primary-color);
	padding: 0.5rem 1rem;
	border-bottom: 1px;
	font-weight: 600;
}

.menu-items:hover {
	filter: brightness(0.8);
}

/* ================Mobile view drop down========================== */

/* #tablet-navbarIcons {
	position: absolute;
} */

/* ============Media Query=========================================== */
@media screen and (min-width: 1200px) and (max-width: 1300px) {
	#navbar > ul {
		padding-inline: 10vw;
	}

	.content .header-icons {
		width: 50%;
	}

	.navbarIcons li p {
		font-size: 0.85rem;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
	#navbar > ul {
		padding-inline: 5vw;
	}

	#navbar .content {
		width: 100vw;
	}

	#navbar .content .header-search {
		width: 30%;
	}

	#navbar .content .header-icons {
		width: 45%;
	}

	.navbarIcons li p {
		font-size: 0.78rem;
	}

	.dropdown-icon img {
		height: 1rem;
		width: 1.2rem;
	}
}

@media screen and (min-width: 950px) and (max-width: 1023px) {
	.navbarIcons {
		display: none;
	}

	#tablet-navbarIcons {
		display: flex;
	}

	#navbar > ul {
		padding-inline: 1vw;
	}
	#navbar .content {
		width: 100vw;
	}
	#navbar .content .header-search {
		width: 30%;
	}

	#navbar .content .header-icons {
		width: 45%;
	}
}

@media screen and (max-width: 949px) {
	/* .navbarIcons li p {
		display: none;
	} */
}

@media screen and (min-width: 850px) and (max-width: 949px) {
	/* .mobile-chat-dropDown {
		width: 18rem;
	} */

	.navbarIcons {
		display: none;
	}

	#tablet-navbarIcons {
		display: flex;
	}
	#navbar > ul {
		padding-inline: 10vw;
	}

	#navbar .content .header-search {
		width: 25%;
	}

	#navbar .content .header-icons {
		width: 55%;
	}
}

@media screen and (min-width: 640px) and (max-width: 849px) {
	/* .mobile-chat-dropDown {
		width: 12rem;
	} */

	.navbarIcons {
		display: none;
	}

	#tablet-navbarIcons {
		display: flex;
	}

	#navbar > ul {
		padding-inline: 2vw;
	}

	#navbar .content .header-search {
		width: 25%;
	}

	.header-search input {
		padding: 0.5rem;
	}

	#navbar .content .header-icons {
		width: 55%;
	}

	/* .navbarIcons li p {
		display: none;
	} */

	.navbarIcons li p img {
		height: 1.5rem;
		width: 1.5rem;
	}
}

@media screen and (max-width: 639px) {
	/* .header-logo img {
		height: 1rem;
		width: 15vw;
	} */

	.mobile-view {
		display: block;
	}

	.content .header-logo {
		width: 10vw;
	}

	.content .header-search {
		width: 78vw;
	}

	.content .header-icons {
		width: 10vw;
	}
	.navbarIcons {
		display: none;
	}

	#tablet-navbarIcons {
		display: flex;
	}

	#navbar > ul {
		padding-inline: 1vw;
	}

	.tablet-view,
	.header-company-logo {
		display: none;
	}

	/* .navbarIcons li p {
		display: none;
	} */

	#mobile-navbarIcons {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: white;
		padding: 1rem 0;
		display: flex;
		width: 100%;
	}

	.header-company-logo-mobile {
		display: block;
	}

	.mobile-open-dropdown {
		width: 70vw;
		height: 90%;
		transition: all 0.5s ease-in-out;
		bottom: 4.4rem !important;
	}

	.mobile-close-dropdown {
		right: -70vw !important;
		width: 60vw;
		height: 90%;
		transition: all 0.5s ease-in-out;
		bottom: 4.4rem !important;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	.navbarIcons li p {
		font-size: 0.7rem;
	}
}

.filter-blue{
	filter: invert(46%) sepia(90%) saturate(1884%) hue-rotate(198deg) brightness(97%) contrast(93%);
}