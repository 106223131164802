.landing-page-form input {
	display: block;
	border: 1px solid #bbbbbb;
	margin-bottom: 1rem;
	width: 60%;
	padding: 0.5rem;
	border-radius: 5px;

	/* display: block; */
    /* width: 100%; */
    /* padding: 0.5rem; */
    /* border-radius: 5px; */
    /* outline: none; */
    /* border: 1px solid silver #bbbbbb; */
    /* margin-top: 0.3rem; */
	padding-right: 50px;
}

.landing-page-form .input-icon{
	/* bottom:1rem !important; */
	right: 40%  !important;
}

.landing-page-form .input-container {
	width:inherit !important; 

}

.landing-page-form input,
.login-or,
.landing-page-form .google-btn,
.landing-page-form .linkedin-btn,
.landing-page-form .sign-btn {
	width: 65% !important;
}

.landing-first-row,
.landing-second-row,
.landing-third-row,
.footer-container {
	padding-inline: 15vw;
}

.login-navbar-mobile {
	display: none !important;
}

/* ============Media Query=========================================== */
@media screen and (min-width: 1200px) and (max-width: 1300px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 10vw;
	}

	.landing-page-form input,
	.login-or,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.landing-page-form .sign-btn {
		width: 80% !important;
	}

	.landing-page-form .input-icon{
		
		right: 25%  !important;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 5vw;
	}

	.landing-page-form input,
	.login-or,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.landing-page-form .sign-btn {
		width: 80% !important;
	}

	.landing-page-form .input-icon{
		
		right: 25%  !important;
	}
}

@media screen and (min-width: 950px) and (max-width: 1023px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 1vw;
	}

	.landing-page-form input,
	.login-or,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.landing-page-form .sign-btn {
		width: 80% !important;
	}

	.landing-page-form .input-icon{
		
		right: 25%  !important;
	}
}

/* @media screen and (max-width: 949px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 10vw;
	}

	.landing-page-form input,
	.login-or,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.landing-page-form .sign-btn {
		width: 85% !important;
	}
} */

@media screen and (min-width: 850px) and (max-width: 949px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 10vw;
	}

	.landing-first-row li:first-child h1 {
		font-size: 1.5rem !important;
		font-weight: 600 !important;
	}

	#login-navbar li:first-child {
		width: 30% !important;
	}
	#login-navbar li:last-child {
		width: 70% !important;
	}

	.landing-page-form .input-icon{
		
		right: 40%  !important;
	}
}

@media screen and (min-width: 640px) and (max-width: 849px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 1vw;
	}

	.landing-page-form input,
	.landing-page-form .sign-btn,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.login-or {
		width: 90% !important;
	}

	.landing-first-row li:first-child h1 {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 1rem;
	}

	.footer-section-container {
		justify-content: space-around;
	}
	#login-navbar li:first-child {
		width: 30% !important;
	}
	#login-navbar li:last-child {
		width: 70% !important;
	}
	.landing-page-form .input-icon{
		
		right: 16%  !important;
	}
}

@media screen and (max-width: 639px) {
	.landing-first-row,
	.landing-second-row,
	.landing-third-row,
	.footer-container {
		padding-inline: 1vw;
	}

	.footer-container {
		margin-bottom: 5rem;
	}
	.landing-first-row,
	.landing-second-row,
	.landing-third-row {
		display: block !important;
	}

	.landing-first-row li {
		width: 100% !important;
	}

	.landing-page-form {
		margin-bottom: 1rem;
	}

	.landing-first-row h1 {
		font-size: 1.8em;
		font-weight: normal !important;
		margin-top: 6rem !important;
		margin-bottom: 1rem;
	}

	.landing-second-row li:first-child {
		width: 100%;
		font-size: 0.9rem;
		text-align: center;
	}

	.landing-second-row {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.landing-second-row li:first-child h1 {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.landing-second-row li:last-child {
		width: 100%;
		text-align: center;
	}

	.landing-second-row li:last-child button {
		padding: 0.5rem !important;
		margin-top: 1rem;
		font-size: 0.9rem;
	}

	.landing-third-row li {
		width: 100% !important;
	}

	.landing-third-row li p {
		font-size: 1.1rem !important;
		font-weight: 600 !important;
		text-align: center;
	}

	.landing-page-form input,
	.login-or {
		width: 100%;
	}

	.landing-page-form input,
	.landing-page-form .sign-btn,
	.landing-page-form .google-btn,
	.landing-page-form .linkedin-btn,
	.login-or {
		width: 100% !important;
	}

	.landing-page-form button,
	.landing-page-form .google-btn {
		padding: 0.5rem;
	}
	.landing-page-form .linkedin-btn {
		padding: 0.25rem;
	}
	.landing-third-row li span p {
		width: 100% !important;
	}

	.landing-third-row li:first-child {
		margin-right: 0rem;
		margin-bottom: 2rem;
	}

	.remember-text {
		font-weight: 500 !important;
	}

	.landing-first-row li:first-child h1 {
		font-weight: 600 !important;
	}

	.landing-page-form .input-icon{
		
		right: 5%  !important;
		padding: 0 !important;
	}

	/* ===========for footer=================== */

	.footer-container ul {
		display: block !important;
	}

	.footer-container ul .footer-section {
		width: 90% !important;
		margin-inline: 1rem;
		margin-bottom: 1rem;
	}

	.footer-section-container {
		width: 96% !important;
		margin-left: 1rem !important;
	}

	.footer-container ul .footer-section-contact {
		align-items: flex-start !important;
	}

	.footer-section-link {
		width: 40% !important;
	}

	.footer-section-contact {
		width: 60% !important;
	}

	.footer-img img {
		height: 3rem !important;
	}

	#footer-social {
		display: flex !important;
	}

	.login-navbar-desktop {
		display: none !important;
	}

	.login-navbar-mobile {
		display: flex !important;
		z-index: 40;
	}

	#login-navbar li:last-child {
		width: 50% !important;
	}
	/* ======================================== */
}

@media (min-width: 0px) and (max-width: 380px) {
	/* .landing-first-row,
	.landing-second-row,
	.landing-third-row, .footer-container {
		padding-inline: 15vw;
	} */

	.landing-third-row li p {
		font-size: 1rem !important;
	}
	.footer-img img {
		height: 2rem !important;
	}

	.footer-copyright {
		font-weight: 500 !important;
		font-size: 0.7rem !important;
	}

	#login-navbar li:last-child {
		width: 70% !important;
	}
}
