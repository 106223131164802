/* ============================= */
#three-section-public-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-public-container {
	display: flex;
	justify-content: space-between;
}

#three-section-public-container .left-container {
	width: 12vw;
}
#three-section-public-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-public-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}
/* ============================= */

.publicUser-member-section {
	box-shadow: var(--profile-card-shadow);
	border-radius: 5px;
	position: relative;
	margin-bottom: 1rem;
	margin-right: 1.5%;
	margin-left: 1.5%;
	padding-bottom: 0.5rem;
	width: 30%;
	height: 16rem;
}

.publicUser-flex-section {
	display: flex;
	flex-wrap: wrap;
	box-shadow: var(--profile-card-shadow);
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: white;
	/* height: 100vh; */
}

.publicUser-section .userName:hover {
	text-decoration: underline;
}

.publicUser-profile_img img {
	height: 5rem;
	width: 5rem;
	position: absolute;
	left: 50%;
	top: 10%;
	transform: translate(-50%);
	border-radius: 50%;
}

.userConnect-btn {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	bottom: 5%;
	border: 1px solid var(--primary-color);
	background-color: white;
	color: var(--primary-color);
	font-weight: bold;
	padding: 0.35rem 1rem;
	margin-top: 0.8rem;
	border-radius: 20px;
	width: 70%;
}

.userPending-btn {
	color: var(--subHeading-text-color);
	border: 1px solid var(--subHeading-text-color);
}

.connection-message {
	font-size: 0.8rem;
	text-align: center;
	color: green;
	font-weight: 550;
}

.connection-message {
	font-size: 0.8rem;
	text-align: center;
	color: red;
	font-weight: 550;
}

.manage-network-container,
.manage-network-tab {
	display: none;
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-public-container {
		padding-inline: 10vw;
	}

	#three-section-public-container .left-container,
	#three-section-public-container .right-container {
		width: 15vw;
	}

	#three-section-public-container .mid-container {
		width: 46vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-public-container {
		padding-inline: 5vw;
	}

	#three-section-public-container .left-container,
	#three-section-public-container .right-container {
		width: 17.5vw;
	}

	#three-section-public-container .mid-container {
		width: 50vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-public-container {
		padding-inline: 1vw;
	}

	#three-section-public-container .left-container,
	#three-section-public-container .right-container {
		width: 20vw;
	}

	#three-section-public-container .mid-container {
		width: 54vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-public-container {
		padding-inline: 10vw;
	}

	#three-section-public-container .left-container {
		width: 25vw;
	}

	#three-section-public-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-public-container .right-container {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-public-container {
		padding-inline: 2vw;
	}

	#three-section-public-container .left-container {
		width: 24vw;
	}

	#three-section-public-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-public-container .right-container {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-public-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
	}

	#three-section-public-container {
		display: block;
	}

	#three-section-public-container .right-container,
	#three-section-public-container .left-container {
		display: none;
	}

	#three-section-public-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}
	.manage-network-container,
	.manage-network-tab {
		display: block;
	}

	.manage-network-tab {
		position: sticky;
		top: 3.6rem;
		z-index: 1;
	}

	.publicUser-member-section {
		height: 13rem;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-public-container {
		padding-inline: 0vw;
	}

	#three-section-public-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-public-container .mid-container {
		margin-left: 0;
		width: 100%;
	}

	.publicUser-profile_img img {
		height: 5rem;
		width: 5rem;
	}

	.publicUser-flex-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding-inline: 2vw;
		padding-bottom: 5rem;
	}

	.member-name {
		margin-top: 2rem;
		font-weight: 600;
	}

	.publicUser-member-section {
		position: relative;
		left: 50%;
		transform: translate(-50%);
		width: 43vw;
	}

	.userConnect-btn {
		font-weight: 550;
		padding: 0.2rem 1rem;
		width: 80%;
	}

	.networ-bg-img img {
		height: 4rem;
	}
	.publicUser-profile_img img {
		height: 4rem;
		width: 4rem;
	}
}

@media (min-width: 0px) and (max-width: 320px) {
	.manage-network-tab ul li {
		padding-left: 0;
		padding-right: 0;
	}

	.member-name {
		font-size: 0.9rem;
	}

	.member-designation {
		font-weight: normal;
	}
}
