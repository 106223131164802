/* =========== for profile form ==================== */
html {
	overflow-y: scroll;
}
.MuiDialog-paperWidthSm {
	min-width: 30vw !important;
	min-height: fit-content !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
	border: none !important;
}
.update-form #job_title {
	border: none;
}

.MuiDialogContent-dividers,
.MuiDialogContent-root {
	padding: 1rem 1.5rem 4rem 1.5rem !important;
}

.MuiGrid-container {
	flex-wrap: nowrap !important;
}

.add-form label,
.update-form label {
	color: var(--subHeading-text-color);
	font-weight: 550;
	font-size: 0.92rem;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	display: block;
}

.skills-edit {
	width: calc(100% - 0.5rem) !important;
}

/* ===================================================== */
/* ===========create job================================ */
.create-job-form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
	width: 100%;
	background-color: var(--primary-light-color);
}

.create-job-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-input {
	padding: 0.5rem 0.5rem !important;
	color: var(--subHeading-text-color);
}

.wrapper select option {
	overflow-y: scroll;
}

/* MODALS STYLING */

/* Edit Modal */

/* Experience Modal */

.add-form {
	position: relative;
	padding-bottom: 1rem;
}

.add-form input,
.add-form select {
	width: 100%;
	display: block;
	/* margin-top: 0.1rem; */
	/* margin-bottom: 0.3rem; */
	padding: 0.4rem 0.3rem;
	outline: none;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 5px;
	font-size: 0.9rem;
}

.skills-edit input,
.language-edit input,
.add-form input,
.add-form select,
.add-form textarea,
.update-form input,
.update-form textarea,
.update-form select {
	background: rgb(251, 251, 251);
}

.add-form textarea {
	height: 8rem;
	width: 100%;
	padding: 0.3rem;
	display: block;
	/* border: 1px solid #e0e0e0; */
	border: 1px solid rgba(0, 0, 0, 0.23);
}

.cross-btn {
	opacity: 0.7;
	font-size: 1.2rem;
	border-radius: 50%;
	margin-top: 0.5rem;
}

.cross-btn:hover {
	background-color: #e0e0e0;
	transition: all 0.3s ease;
}

.save-btn {
	font-weight: bold;
	outline: none;
	border: none;
	padding: 0.5rem 1.5rem;
	font-size: 1.1rem;
	border-radius: 3px;
	border: 1.8px solid var(--primary-color);
	position: absolute;
	right: 1%;
	margin-top: 2rem;
}

.save-btn:hover {
	background-color: #4179c8;
	transition: all 0.3s ease;
	color: white;
}

.investor-delete-btn {
	right: 7.8rem !important;
}

.delete-btn {
	color: gray;
	font-weight: bold;
	outline: none;
	border: none;
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	border-radius: 3px;
	position: absolute;
	right: 6.5rem;
	margin-top: 2rem;
	border: 1.8px solid rgb(255 96 96);
}

/* #single-modal .MuiDialog-paperWidthSm {
	width: 600px !important;
} */

#candidate-modal .MuiDialog-paperWidthSm {
	max-width: 70vw !important;
}

.delete-btn:hover {
	background-color: #f32424;
	color: white;
	transition: all 0.3s ease;
}

.dialog-box {
	background-color: rgba(0, 0, 0, 0.5);
}

.add-form .start-date-input,
.add-form .end-date-input {
	display: inline;
}
/* about modal */

.about-textarea {
	height: 10rem;
	width: 100%;
	display: block;
	margin-bottom: 1rem;
	outline: none;
	padding: 0.3rem;
}

.about-dialog-box {
	max-width: 30vw!;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: 0px !important;
	border: none !important;
}

/* UPDATE Modal */

.update-form {
	position: relative;
	padding-bottom: 2rem;
}

.update-form input,
.update-form select {
	width: 100%;
	display: block;
	padding: 0.4rem;
	outline: none;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	font-size: 0.9rem;
	color: var(--subHeading-text-color);
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-input {
	border: none;
}

.update-form textarea {
	height: 8rem;
	width: 100%;
	padding: 0.3rem;
	display: block;
	border: 1px solid rgba(0, 0, 0, 0.23);
	font-size: 0.9rem;
	color: var(--subHeading-text-color);
	border-radius: 5px;
	background: rgb(245, 248, 248);
}

/* Experience Modal */

.start-date-label {
	display: flex;
}

/* .start-date input {
	display: block;
} */

/* Attachment Modal */

.upload-section {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	/* background: lightgray; */
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.upload-logo {
	text-align: center;
	font-size: 3rem;
	display: block;
}

.upload-header {
	font-weight: bold;
}

/* Image upload Modal */

.img-upload-section {
	position: relative;
	margin-bottom: 2rem;
}

.img-upload {
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.img-upload-content {
	text-align: center;
	font-weight: bold;
}

/* common modal form styling */

.date-section ul {
	display: flex;
	justify-content: space-between;
}

.date-section li {
	display: inline;
	width: 48%;
}

.date-section select {
	border-radius: 5px;
	font-size: 0.9rem;
}

.delete-confirm {
	display: none;
}

/* ===================USER INFO STYLING================== */

.tab-chat-rightContainer {
	width: 100%;
	background-color: white;
}

.tab-chat-rightContainer .user-image {
	height: 5rem;
	width: 5rem;
	border-radius: 50%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
	margin-top: 1rem;
}

.tab-chat-rightContainer .main-heading {
	text-align: center;
}

.tab-chat-rightContainer .detail-heading {
	margin: 0.7rem 0;
	margin-left: 1rem;
	color: var(--subHeading-text-color);
}

.tab-chat-rightContainer .skills {
	display: flex;
	flex-wrap: wrap;
}

.tab-chat-rightContainer .skills li {
	background-color: var(--profile-card-color);
	padding: 0.5rem;
	border-radius: 5px;
	margin: 0.5rem 0.7rem;
	font-size: 0.8rem;
	font-weight: 500;
	box-shadow: var(--profile-card-shadow);
}

.tab-chat-rightContainer .attachment {
	margin: 0.5rem;
	font-size: 0.9rem;
	display: flex;
	flex-wrap: wrap;
}

.tab-chat-rightContainer .attachment a {
	display: flex;
	align-items: center;
	color: var(--primary-color);
	font-weight: 550;
	margin-bottom: 1rem;
}

.tab-chat-rightContainer .attachment-name {
	padding: 0.7rem;
	background-color: var(--profile-card-color);
	margin: 0 0.3rem;
	border-radius: 5px;
}

.tab-chat-rightContainer .user-message-social-media {
	display: flex;
	justify-content: space-between;
	width: 35%;
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.tab-chat-rightContainer .user-message-social-media img {
	height: 1.5rem;
	width: 1.5rem;
}

/* ===================================================== */

/* =======for cross button of autocomplete=============== */
.css-16awh2u-MuiAutocomplete-root
	.MuiOutlinedInput-root
	.MuiAutocomplete-endAdornment,
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
	display: none !important;
}
/* ====================================================== */

/* ========== for created investor edit form calendar ============= */

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	border: none !important;
}

.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root{
	padding: 0 !important;
}
/* ====================================================== */

/* ================CANDIDATE DETAILS =================== */

#candidate-mainContainer {
	display: flex;
	margin-top: 1rem;
	color: var(--heading-text-color);
	/* width: 80rem; */
}

.candidate-leftContainer {
	width: 25%;
	background-color: rgba(69, 138, 240, 0.1);
	margin-right: 3%;
	position: relative;
	padding: 1rem;
}

.candidate-rightContainer {
	width: 50vw;
}

.candidate-name-mobile,
.button-section-mobile {
	display: none;
}

.candidate-leftContainer .candidate-image {
	height: 8rem;
	width: 8rem;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.candidate-leftContainer .candidate {
	margin-top: 8rem;
}

.candidate-leftContainer .title {
	font-size: 0.9rem;
	color: black;
	font-weight: 550;
	text-align: center;
}

.candidate-leftContainer .candidateModal-skill {
	justify-content: start;
	width: 100%;
	word-break: break-all;
}

.candidate-leftContainer .candidate-skill {
	max-width: 100%;
	width: fit-content;
	flex-wrap: wrap;
}

.candidate-leftContainer .candidate-skill li {
	background-color: var(--primary-light-color);
	background-color: #dce8fe;
	padding: 0.3rem 0.8rem;
}

.candidate-leftContainer .certificates li {
	font-size: 0.8rem;
}

/* MOdal Css */
/* .MuiDialog-paperWidthSm {
max-width: 40vw !important;
} */

#modal-cross-btn {
	position: absolute;
	right: 2rem;
}

.candidate-rightContainer .candidate-name {
	color: var(--heading-text-color);
}

.candidate-rightContainer .title {
	font-weight: 550;
	margin-bottom: 1rem;
}

.candidate-rightContainer .resume-btn {
	outline: none;
	padding: 0.5rem 1rem;
	font-size: 0.9rem;
	font-weight: 600;
	border: 1.9px solid gray;
	border-radius: 5px;
	margin-right: 2rem;
}

.candidate-rightContainer .message-btn {
	border: none;
	outline: none;
	background-color: var(--primary-color);
	color: white;
	border-radius: 20px;
	font-weight: 600;
	padding: 0.5rem 2rem;
	font-size: 1rem;
}

.candidate-rightContainer .about-section {
	background-color: rgba(69, 138, 240, 0.1);
	margin-top: 1rem;
	padding: 1rem;
}

.candidate-rightContainer .about > p {
	color: var(--primary-color);
	font-size: 0.8rem;
}

.candidate-rightContainer .experience {
	margin-top: 1rem;
}
/* ==================================================== */

/* ==================== */
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-input {
	padding: 0.5rem !important;
}
/* =========================== */
/* ============= Profile Image upload slide============= */
.MuiSlider-root {
	width: 75% !important;
}

#landing-alert {
	position: absolute !important;
	top: 5rem !important;
	right: 0 !important;
}
/* ===================================================== */
/* ===================================================== */

/* =============== Snackbar Alert======================== */
/* .css-f779bt-MuiStack-root {
	width: fit-content !important;

} */
/* ====================================================== */

/* ==============for investment data picker======================== */
#investment-update-form .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
	border: none !important;
}
/* ================================================================ */

/* ======================FOR NOT FOUND PAGE=========================== */
.not-found-heading {
	font-size: 4rem;
	line-height: 4rem;
}
/* ================================================================ */

/* ============COMMON CSS FOR WORD BREAK============================= */
.word-break-css {
	/* word-wrap: break-word; */
	word-break: break-word;
}
/* ================================================================ */

/* ==================== FORM LOGIN ALERT MODAL====================== */
#login-alert .MuiDialogContent-dividers,
#login-alert .MuiDialogContent-root {
	padding-top: 4rem !important;
}
/* ================================================================ */
/* ================= MEDIA QUERY SECTION =========================== */
@media (min-width: 1201px) and (max-width: 1300px) {
	.MuiDialog-paperWidthSm {
		min-width: 50vw !important;
		min-height: 15rem !important;
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	.MuiDialog-paperWidthSm {
		min-width: 50vw !important;
		min-height: 15rem !important;
	}
	.applied-social-mobile span {
		display: none !important;
	}
}

@media (min-width: 950px) and (max-width: 1023px) {
	.MuiDialog-paperWidthSm {
		min-width: 50vw !important;
		min-height: 15rem !important;
	}
	.applied-social-mobile span {
		display: none !important;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	.MuiDialog-paperWidthSm {
		min-width: 60vw !important;
		min-height: 15rem !important;
	}

	.candidate-leftContainer {
		width: 30%;
	}

	.candidate-rightContainer {
		width: 67%;
	}
	.applied-social-mobile span {
		display: none !important;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	.MuiDialog-paperWidthSm {
		min-width: 95vw !important;
		/* min-height: fit-content; */
		min-height: 90vh !important;
	}

	.about-textarea,
	.update-about {
		height: 15rem !important;
	}

	.profile-heading-section {
		height: 15rem;
		padding-top: 1rem;
		margin-bottom: 2rem;
	}

	.profile-heading-section .chat-cover-image {
		height: 15rem;
		opacity: 0.2;
	}

	.candidate-leftContainer {
		width: 37%;
	}

	.candidate-rightContainer {
		width: 60%;
	}

	.applied-social-mobile span {
		display: none !important;
	}

	.skills-edit {
		width: calc(100% - 0.5rem) !important;
	}

	/* .applied-social-mobile {
		width: 70% !important;
	} */
}
@media (min-width: 0px) and (max-width: 640px) {
	.MuiDialog-paperScrollPaper {
		/* max-height: calc(95vh - 3rem) !important; */
		max-height: 100% !important;
	}
	.MuiDialog-paper {
		margin: 0 !important;
	}

	.MuiDialog-paperWidthSm {
		min-width: 98vw !important;
		min-height: calc(95%) !important;
	}

	.MuiDialogContent-dividers {
		padding: 1rem 0.5rem 3rem 0.5rem !important;
		/* max-height: 90vh; */
		/* max-height: fit-content; */
	}

	.add-form input,
	.add-form select {
		padding: 0.4rem 0.5rem;
		font-size: 0.9rem !important;
		border-radius: 5px;
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.add-form textarea {
		border-radius: 5px;
		font-size: 0.9rem;
	}

	.update-form input,
	.update-form select {
		padding: 0.45rem 0.5rem !important;
		font-size: 0.9rem;
	}

	#investment-update-form
		.css-16awh2u-MuiAutocomplete-root
		.MuiOutlinedInput-root
		.MuiAutocomplete-input {
		padding: 0.3rem 0.5rem !important;
	}

	.save-btn {
		/* padding: 0.2rem 0.6rem; */
		font-size: 0.9rem;
		margin-top: 1rem;
	}

	.delete-btn {
		/* padding: 0.2rem 0.4rem; */
		font-size: 0.9rem;
		margin-top: 1rem;
	}

	.delete-btn {
		right: 7rem;
	}

	.update-about {
		height: 15rem !important;
	}

	.profile-heading-section {
		height: 15rem;
		padding-top: 1rem;
		margin-bottom: 2rem;
	}

	.profile-heading-section .chat-cover-image {
		height: 15rem;
		opacity: 0.2;
	}

	/* ======== candidate modal============= */
	#candidate-mainContainer {
		display: block;
	}

	.candidate-leftContainer,
	.candidate-rightContainer {
		width: 100%;
	}

	.candidate-name-mobile,
	.button-section-mobile {
		display: block;
	}

	.candidate-name,
	.candidate-rightContainer .title,
	.candidate-rightContainer .resume-btn {
		display: none;
	}

	.applied-social-mobile {
		width: 80% !important;
		justify-content: space-between !important;
	}

	.applied-social-mobile li {
		margin-left: 0.3rem;
		margin-right: 0.3rem;
	}

	.applied-social-mobile span {
		font-size: 0.8rem;
	}

	.skills-edit {
		width: calc(100% - 0.5rem) !important;
	}

	.not-found-container {
		top: unset !important;
		margin-top: 15rem;
	}
	.not-found-heading {
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
}
@media (min-width: 0px) and (max-width: 450px) {
	.applied-social-mobile {
		width: 100% !important;
		justify-content: space-between !important;
	}
}
@media (min-width: 0px) and (max-width: 380px) {
	/* .MuiDialog-paperWidthSm {
		min-width: 95vw !important;
	} */
	.MuiDialogContent-dividers {
		padding: 1rem 0.5rem 3rem 0.5rem !important;
	}

	.tab-chat-rightContainer .user-message-social-media {
		width: 70%;
	}

	.tab-chat-rightContainer .user-message-social-media img {
		height: 1.5rem;
		width: 1.5rem;
	}

	.applied-social-mobile {
		width: 100% !important;
	}

	.add-form input,
	.add-form select,
	.add-form textarea,
	.update-form input,
	.update-form select {
		font-size: 0.8rem !important;
	}
}

@media (min-width: 0px) and (max-width: 300px) {
	.applied-social-mobile {
		justify-content: space-evenly;
	}
	.applied-social-mobile span {
		display: none;
	}
}

/* ============== chat text editor======== */
/* .tox .tox-statusbar {
	display: none !important;
}

.tox:not([dir="rtl"]) {
	width: 100% !important;
	height: 5rem !important;
}
.tox-tinymce-aux,
.tox .tox-editor-header {
	display: none;
}

#tinymce p {
	margin-top: 0rem !important;
	margin-bottom: 0rem !important;
} */

#chat-quill .ql-editor {
	width: 100% !important;
	min-height: 2rem !important;
	max-height: 10rem !important;
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
	word-wrap: break-word;
	word-break: break-all;
	overflow-x: hidden;
	display: flex !important;
	flex-direction: column !important;
	position: relative;
}

#chat-quill .ql-editor p {
	display: inline !important;
}

#chat-quill .ql-container {
	min-height: 4.5rem !important;
	max-height: 10rem !important;
	height: fit-content;
	border-radius: 5px !important;
}

/* ============for mobile screen ============== */

#chat-quill-mobile .ql-editor {
	width: 100% !important;
	min-height: 2.5rem !important;
	max-height: 10rem !important;
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
	word-wrap: break-word;
	outline: none;
	padding: 10px 10px !important;
	word-break: break-all;
	overflow-x: hidden;
	display: flex !important;
	flex-direction: column !important;
	position: relative;
}

#chat-quill-mobile .ql-editor p {
	display: inline !important;
}

#chat-quill-mobile .ql-container {
	min-height: 2.5rem !important;
	max-height: 10rem !important;
	height: fit-content;
	border-radius: 5px !important;
	/* border: 1px solid lightgray; */
}

/* =============================================== */

/* =============== for mini chat================== */
#chat-quill-mini-chat .ql-editor {
	width: 100% !important;
	min-height: 2.5rem !important;
	max-height: 10rem !important;
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
	word-wrap: break-word;
	word-break: break-all;
	overflow-x: hidden;
	display: flex !important;
	flex-direction: column !important;
	position: relative;
}

#chat-quill-mini-chat .ql-editor p {
	display: inline !important;
}

#chat-quill-mini-chat .ql-container {
	min-height: 2.5rem !important;
	max-height: 10rem !important;
	height: fit-content;
	border-radius: 5px !important;
}
#chat-quill-mini-chat .ql-container.ql-snow {
	border: none;
}

#chat-quill-mini-chat .ql-editor {
	padding: 0;
	outline: none;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

/* =============================================== */
div.edit {
	position: absolute;
	bottom: 1px;
	width: 100%;
	border-top: 1px solid #c6c6c6;
	min-height: 40px;
	max-height: 10rem;
	z-index: 1;
	padding: 0.9rem 0 0.9rem 0;
	background-color: #f4f9f9;
}

div.edit span {
	min-height: 3rem;
}

div.edit:focus {
	outline: none;
	border-top: 1px solid lightgray;
}
.parser p {
	display: inline !important;
}

/* ============ for  image modal=========== */

#image-modal .MuiDialogContent-dividers,
#image-modal .MuiDialogContent-root {
	padding-bottom: 1rem !important;
}
/* ======================================== */
