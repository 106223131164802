@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

* {
	font-family: "Open Sans", sans-serif;
}

body {
	background-color: #f4f5f7;
}

/* LEFT CONTAINER SECTION */

/* profile section */
.bg-img {
	height: 3.5rem;
}

.profile-img {
	height: 4rem;
	width: 4rem;
	position: relative;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
}

.name {
	margin-top: -2rem;
	word-wrap: break-word;
	padding-inline: 0.2rem;
}

.img:hover .name {
	text-decoration: underline;
	text-decoration-color: #0a66c2;
	cursor: pointer;
}

.read-more p:nth-last-child(2) {
	display: inline;
}

/* .home-profile-section{
	 max-height: 55vh;
	 overflow-y: auto;
} */

/* page section */

.page {
	width: 100%;
	height: fit-content;
	box-shadow: 2px 2px 4px silver, -2px -2px 4px silver;
	margin-top: 1rem;
	border-radius: 5px;
	position: sticky;
	top: 5rem;
	padding: 1rem 0.5rem;
	background-color: white;
	overflow-y: auto;
	max-height: 20vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.page {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-heading {
	padding-left: 1rem;
}

/* .my-pages:last-child {
	border-bottom: none;
} */

.my-pages img {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
}

.company-name a {
	color: black;
}

.company-name a span {
	color: gray;
	font-size: 0.9rem;
}

/* MID CONTAINER SECTION */
#three-section-home-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-home-container > ul {
	display: flex;
	justify-content: space-between;
}

#three-section-home-container .left-container {
	position: fixed;
	width: 12vw;
}
#three-section-home-container .mid-container {
	width: 42vw;
	margin-left: 14vw;
	border-radius: 0px;
}

#three-section-home-container .right-container {
	position: fixed;
	right: 15vw;
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}

.post-reaction li a:hover {
	transition: all 0.2s ease-in-out;
}

/* RIGHT CONTAINER SECTION */

/* .right-container {
	width: 15vw;
	height: 10vh;
	border: 1px solid silver;
	border-radius: 5px;
	padding: 0.5rem 1rem;
} */

/* loader */

.loading img {
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-home-container {
		padding-inline: 10vw;
	}

	#three-section-home-container .left-container {
		width: 15vw;
	}

	#three-section-home-container .right-container {
		width: 15vw;
		right: 10vw;
	}

	#three-section-home-container .mid-container {
		width: 46vw;
		margin-left: 17vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-home-container {
		padding-inline: 5vw;
	}

	#three-section-home-container .left-container {
		width: 17.5vw;
	}

	#three-section-home-container .right-container {
		width: 17.5vw;
		right: 5vw;
	}

	#three-section-home-container .mid-container {
		width: 50vw;
		margin-left: 20vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-home-container {
		padding-inline: 1vw;
	}

	#three-section-home-container .left-container {
		width: 20vw;
	}
	#three-section-home-container .right-container {
		width: 20vw;
		right: 1vw;
	}

	#three-section-home-container .mid-container {
		width: 54vw;
		margin-left: 22vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-home-container {
		padding-inline: 10vw;
	}

	#three-section-home-container .left-container {
		width: 25vw;
	}

	#three-section-home-container .mid-container {
		margin-right: 0;
		width: 53vw;
		margin-left: 27vw;
	}

	#three-section-home-container .right-container {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-home-container {
		padding-inline: 2vw;
	}

	#three-section-home-container .left-container {
		width: 24vw;
	}

	#three-section-home-container .mid-container {
		margin-right: 0;
		width: 70vw;
		margin-left: 26vw;
	}

	#three-section-home-container .right-container {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-home-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
	}

	#three-section-home-container > ul {
		display: block;
	}

	#three-section-home-container .right-container,
	#three-section-home-container .left-container {
		display: none;
	}

	#three-section-home-container .mid-container {
		margin-left: 0;
		width: 98vw;
		margin-right: 0;
		margin-bottom: 5rem;
	}

	.page {
		display: none;
	}

	.profile .bg-img {
		border-radius: 0;
	}

	.post-reaction li i,
	.post-reaction li span {
		font-size: 0.8rem;
	}

	.post-reaction li a {
		padding: 0.2rem 0;
	}

	.post-person h3 {
		font-size: 0.85rem;
		font-weight: bold;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-home-container {
		padding-inline: 0vw;
	}

	#three-section-home-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-home-container .mid-container {
		margin-left: 0;
		width: 100%;
		margin-right: 0;
		margin-bottom: 7rem;
	}

	.post-details .main-content {
		font-size: 0.8rem;
	}
}
