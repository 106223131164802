/* ===================================== */
#two-section-createJob-container {
	display: flex;
	margin-top: 5rem;
	padding-left: 15vw;
	padding-right: 15vw;
}

/* #info-section {
	height: auto;
	border-radius: 10px;
	box-shadow: 0.1px 0.1px 5px 0px gray;
	background-color: white;
	width: 100%;
} */

.user-leftContainer {
	width: 52vw;
	margin-right: 3vw;
	border-radius: 10px;
	background-color: white;
}

.user-rightContainer {
	/* border: 1px solid black; */
	width: 15vw;
}
/* ===================================== */

.create-job-form input,
.create-job-form select,
.create-job-form textarea {
	width: 100%;
	display: block;
	padding: 0.4rem 0.3rem;
	background-color: #f4f6f8;
	outline: none;
	border-radius: 5px;
	font-size: 0.9rem;
	border: 1px solid #d8d4d4;
	color: var(--subHeading-text-color);
}

#two-section-createJob-container
	.css-16awh2u-MuiAutocomplete-root
	.MuiOutlinedInput-root
	.MuiAutocomplete-input {
	padding: 0.4rem 0.3rem !important;
}

.create-job-form .autoComplete,
.create-job-form input:nth-child(1) {
	background-color: #f4f6f8;
	outline: none;
	margin-bottom: 1rem;
	border-radius: 5px;
	/* border: none; */
	margin: 0rem !important;
}

.create-job-form
	.css-16awh2u-MuiAutocomplete-root
	.MuiOutlinedInput-root
	.MuiAutocomplete-input,
.create-job-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
/* .create-job-form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root  */ {
	border: 1px solid #d8d4d4 !important;
	border-radius: 5px;
}

.create-job-form label {
	background-color: white;
	border: none;
	margin-bottom: 0.5rem;
}

.create-job-form-line {
	border: 1px solid #e0e0e0;
	margin-bottom: 1rem;
}

/* ================= MEDIA QUERY SECTION =========================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#two-section-createJob-container {
		padding-inline: 10vw;
	}

	.user-leftContainer {
		width: 65vw;
	}

	.user-rightContainer {
		width: 15vw;
	}
}
@media (min-width: 1024px) and (max-width: 1200px) {
	#two-section-createJob-container {
		padding-inline: 5vw;
	}

	.user-leftContainer {
		width: 75vw;
	}

	.user-rightContainer {
		width: 15vw;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#two-section-createJob-container {
		padding-inline: 1vw;
	}

	.user-leftContainer {
		width: 79vw;
	}
	.user-rightContainer {
		width: 19vw;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#two-section-createJob-container {
		padding-inline: 10vw;
		display: block;
	}
	.user-leftContainer {
		width: 80vw;
	}

	.user-rightContainer {
		display: none;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#two-section-createJob-container {
		padding-inline: 2vw;
		display: block;
	}
	.user-leftContainer {
		width: 96vw;
	}

	.user-rightContainer {
		height: 20rem;
		display: none;
	}
}

@media (min-width: 0px) and (max-width: 640px) {
	#two-section-createJob-container {
		padding-inline: 0;
		display: block;
		margin-top: 3rem;
	}

	.user-leftContainer {
		width: 100vw;
		margin-bottom: 5rem;
	}

	.create-job-form textarea {
		height: 5rem;
	}

	.create-job-form,
	.company-form {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
	}

	.create-job-form input,
	.create-job-form select,
	.create-job-form textarea,
	#two-section-createJob-container
		.css-16awh2u-MuiAutocomplete-root
		.MuiOutlinedInput-root
		.MuiAutocomplete-input {
		padding: 0.3rem 0.5rem !important;
		font-size: 0.9rem;
	}

	#two-section-createJob-container .create-job-button {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		padding-inline: 1rem;
		font-size: 0.9rem;
		border: 1px solid var(--primary-color);
		color: var(--subHeading-text-color);
	}
}
