/* ============================= */

.mobile-startupDetails-section,
.manage-startup {
	display: none;
}

#three-section-startupDetails-container {
	display: flex;
	padding-inline: 15vw;
	margin-top: 5rem;
}

#three-section-startupDetails-container {
	display: flex;
	justify-content: space-between;
}

#three-section-startupDetails-container .left-container {
	width: 12vw;
}
#three-section-startupDetails-container .mid-container {
	width: 42vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-radius: 0px;
}

#three-section-startupDetails-container .right-container {
	width: 12vw;
	/* border: 1px solid purple; */
}

.mid-container {
	width: 35vw;
	border-radius: 10px;
	height: auto;
}
/* ============================= */

/* STARTUP DETAILED PAGE */

.detailed-page-redirect {
	cursor: pointer;
}

#user-profile .mid-container {
	background-color: #f6f6f6;
}

.company-detailed {
	background-color: white;
	margin-bottom: 1rem;
	border-radius: 10px;
	padding: 0.7rem;
}

.company-detailed h4 {
	color: var(--subHeading-text-color);
}

.detailed-company-location {
	width: 100%;
}

.company-detail-section ul {
	display: flex;
}

.company-detail-section ul li {
	margin-right: 0.5rem;
}

.detailed-social span {
	font-size: 0.8rem;
	font-weight: 500;
	color: var(--primary-color);
}

/*  .detail-lower-flex {
	display: flex;
} */

.detailed-company-location {
	font-weight: 550;
	font-size: 0.8rem;
}

/*  .detail-lower-flex h6 {
	margin-right: 1rem;
	color: var(--primary-color);
	font-weight: 500;
} */

.company-detail-section .detailed-social li {
	display: flex;
	margin-top: 0.1rem;
}

.company-detail-section .detailed-social li a {
	display: flex;
}

.company-detail-section .detailed-social img {
	height: 1.1rem;
	width: 1.1rem;
	margin-right: 0.5rem;
}

.detailed-founder-section ul {
	display: flex;
	flex-wrap: wrap;
}

.detailed-founder-section li {
	/* display: flex; */
	padding: 0.7rem;
	border-radius: 5px;
}

.detailed-founder-section li h5,
.detailed-founder-section li h4 {
	display: flex;
	align-self: center;
	color: var(--primary-color);
	font-weight: 550;
	border: none;
	padding-bottom: 0;
}

.detailed-acquisition-section td {
	font-size: 0.9rem;
	font-weight: 500;
}

.detailed-acquisition-section .description {
	font-size: 10px;
	text-align: left;
	width: 25%;
}

.detailed-table {
	width: 100%;
	margin-top: 0.5rem;
	text-align: center;
}

.detailed-table tr th {
	border-bottom: 1px solid var(--primary-light-color);
	color: var(--subHeading-text-color);
	padding-bottom: 0.5rem;
}

.detailed-table tr td {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid var(--primary-light-color);
	font-weight: 550;
	font-size: 15px;
	color: var(--subHeading-text-color);
}

.detailed-table tr th {
	font-size: 0.9rem;
}

.detailed-table tr td img {
	position: relative;
	left: 50%;
	transform: translate(-50%);
}

.detailed-valuation-section th {
	font-weight: bold;
	font-size: 12px;
}

.detailed-investment-table {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.detailed-investment-table tr td,
.detailed-valuation-table tr td {
	color: var(--primary-color);
	font-weight: 550;
}

.detailed-competitor-table {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.detailed-acquisition-table {
	padding-left: 1rem;
	padding-right: 1.5rem;
}

.detailed-para {
	font-size: 0.8rem;
}

.detailed-graph-section img {
	height: 20rem;
	width: 100%;
}

/* =================== Media Queries ====================== */

@media (min-width: 1201px) and (max-width: 1300px) {
	#three-section-startupDetails-container {
		padding-inline: 10vw;
	}

	#three-section-startupDetails-container .left-container,
	#three-section-startupDetails-container .right-container {
		width: 15vw;
	}

	#three-section-startupDetails-container .mid-container {
		width: 46vw;
	}

	.detailed-table tr th,
	.detailed-table tr td {
		font-size: 0.8rem;
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	#three-section-startupDetails-container {
		padding-inline: 5vw;
	}

	#three-section-startupDetails-container .left-container,
	#three-section-startupDetails-container .right-container {
		width: 17.5vw;
	}

	#three-section-startupDetails-container .mid-container {
		width: 50vw;
	}

	.detailed-table tr th,
	.detailed-table tr td {
		font-size: 0.8rem;
	}
}
@media (min-width: 950px) and (max-width: 1023px) {
	#three-section-startupDetails-container {
		padding-inline: 1vw;
	}

	#three-section-startupDetails-container .left-container,
	#three-section-startupDetails-container .right-container {
		width: 20vw;
	}

	#three-section-startupDetails-container .mid-container {
		width: 54vw;
	}

	.detailed-table tr th {
		font-size: 0.78rem;
	}

	.detailed-table tr td {
		font-size: 0.8rem;
	}
}
@media (min-width: 850px) and (max-width: 949px) {
	#three-section-startupDetails-container {
		padding-inline: 10vw;
	}

	#three-section-startupDetails-container .left-container {
		width: 25vw;
	}

	#three-section-startupDetails-container .mid-container {
		margin-right: 0;
		width: 53vw;
	}

	#three-section-startupDetails-container .right-container {
		display: none;
	}

	.detailed-table tr th {
		font-size: 0.75rem;
	}

	.detailed-table tr td {
		font-size: 0.8rem;
	}
}
@media (min-width: 641px) and (max-width: 849px) {
	#three-section-startupDetails-container {
		padding-inline: 2vw;
	}

	#three-section-startupDetails-container .left-container {
		width: 24vw;
	}

	#three-section-startupDetails-container .mid-container {
		margin-right: 0;
		width: 70vw;
	}

	#three-section-startupDetails-container .right-container {
		display: none;
	}

	.detailed-table tr th {
		font-size: 0.75rem;
	}

	.detailed-table tr td {
		font-size: 0.8rem;
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	#three-section-startupDetails-container {
		padding-inline: 1vw;
		width: 100vw;
		margin-top: 4rem;
		margin-bottom: 5rem;
	}

	#three-section-startupDetails-container {
		display: block;
	}

	#three-section-startupDetails-container .right-container,
	#three-section-startupDetails-container .left-container {
		display: none;
	}

	#three-section-startupDetails-container .mid-container {
		margin-left: 0;
		width: 98vw;
	}

	.detailed-table {
		display: none;
	}

	.mobile-startupDetails-section,
	.manage-startup {
		display: block;
	}

	.mobile-startupDetails-section .heading {
		font-weight: bold;
		color: var(--subHeading-text-color);
	}

	/* temporary change for mobile */
	.vlog-flex-section li {
		max-width: 80%;
		text-align: justify;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	#three-section-startupDetails-container {
		padding-inline: 0vw;
	}

	#three-section-startupDetails-container .left-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	#three-section-startupDetails-container .mid-container {
		margin-left: 0;
		width: 100%;
	}
}

@media (min-width: 0px) and (max-width: 350px) {
	.mobile-startupDetails-section,
	.investment-info-section,
	.company-detailed {
		padding: 0.3rem !important;
	}

	.detailed-social li a span {
		display: none !important;
	}
}
