.slider-container {
	width: 100%;
}

.slider {
	width: 100%;
}
.slide-container h3 {
	border: 1px solid red;
	width: 10rem;
}

.slick-track {
	min-width: 10000rem !important;
}

.slick-arrow {
	/* background-color: black !important; */
	border: 50%;
	z-index: 10
}

.slick-next:before, .slick-prev:before{
	color: black !important;
}
