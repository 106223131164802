.contactus-form span input {
	width: 96%;
}

@media (min-width: 0px) and (max-width: 640px) {
	.contactus-form {
		width: 95% !important;
        margin-inline: 2.5%;
	}
}

/* rgba(70, 90, 126, 0.4 */
